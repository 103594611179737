import {createWithEqualityFn} from "zustand/traditional";
import {shallow} from "zustand/shallow";
import {AccessRole, AccessUser} from "./AccessModels";

interface AccessState {
    users: AccessUser[];
    setUsers: (users: AccessUser[]) => void;
    roles: AccessRole[];
    filter: string;
    setFilter: (filter: string) => void;
    showUserModal: boolean;
    setShowUserModal: (showUserModal: boolean) => void;
    currentUser?: AccessUser;
    setCurrentUser: (currentUser?: AccessUser) => void;
}

export const useAccessStore = createWithEqualityFn<AccessState>((set) => ({
    users: [],
    setUsers: (users: AccessUser[])=> {
        set({users: users});
    },
    roles: [
        new AccessRole('talisman-admin', 'Administrator'),
        new AccessRole('talisman-developer', 'Developer')
    ],
    filter: '',
    setFilter: (filter: string)=> {
        set({filter: filter?.toLowerCase()});
    },
    showUserModal: false,
    setShowUserModal: (showUserModal: boolean) => {
        set({showUserModal: showUserModal});
    },
    setCurrentUser: (currentUser?: AccessUser) => {
        set({currentUser: currentUser});
    }
}), shallow)


