import {
    Library,
    OpenApi31Document, OpenApi31DocumentImpl,
} from "@apicurio/data-models";
import React from "react";

export function splitByUppercase(inputWord: string): string {
    return inputWord.replace(/(?=[A-Z])/g, " ").trim();
}

export function docIsOpenApi(filename: string, code?: string): boolean {
    try {
        if (filename.endsWith(".openapi.json")){
            return true;
        }
        if (code) {
            let document = Library.readDocumentFromJSONString(code);
            return true;
        }
        return false;
    } catch (e) {
        return false;
    }
}

export function codeToOpenApiDocument(code?: string): OpenApi31Document | undefined {
    try {
        if (code === undefined || code?.trim().length === 0) {
            return new OpenApi31DocumentImpl();
        } else {
            let document = Library.readDocumentFromJSONString(code);
            return document as OpenApi31Document;
        }
    } catch (e) {
        return undefined;
    }
}

export function openApiDocumentToCode(document: OpenApi31Document): string | undefined {
    try {
        return JSON.stringify(Library.writeDocument(document),null, 2);
    } catch (e) {
        return undefined;
    }
}

export function useAutoSave(callback:() => void, delay = 1000, key: string = '') {

    const savedCallback = React.useRef<() => void>(); // to save the current "fresh" callback

    // keep callback ref up to date
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // create the interval
    React.useEffect(() => {
        // function to call the callback
        function runCallback() {
            savedCallback.current?.();
        };
        // run the interval
        let interval = setInterval(runCallback, delay);
        return () => clearInterval(interval);
    }, [delay, key]);
}
