
import React from 'react';
import '../../../designer/karavan.css';
import {useAppConfigStore} from "../../../api/ProjectStore";
import {shallow} from "zustand/shallow";
import "../dashboard.css"
import {Tbody, Td, Tr} from "@patternfly/react-table";
import {useMetricStore} from "../DashboardStore";
import {Metric} from "../DashboardModels";
import {sumMetrics} from "../DashboardFunc";


export function IntegrationTotalRow() {

    const [metrics] = useMetricStore((s) => [s.metrics], shallow)
    const [config] = useAppConfigStore((state) => [state.config], shallow)
    const form = new Intl.NumberFormat('en-US');


    const metric: Metric = sumMetrics(metrics).filter(m => m.env === config.environment).at(0) || new Metric();

    return (
        metric.total > 0 ? <Tbody>
            <Tr key={0}>
                <Td/>
                <Td/>
                <Td/>
                <Td/>
                <Td/>
                <Td/>
                <Td/>
                <Td/>
                <Td style={{textAlign: "right"}}>
                    {<span style={{whiteSpace:"nowrap"}}>{form.format(metric.total)}</span>}
                </Td>
                <Td style={{textAlign: "right"}}>
                    {<span style={{whiteSpace:"nowrap"}}>{form.format(metric.succeeded)}</span>}
                </Td>
                <Td style={{textAlign: "right"}}>
                    {<span style={{whiteSpace:"nowrap"}}>{form.format(metric.failed)}</span>}
                </Td>
                <Td/>
                <Td style={{textAlign: "right"}}>
                    {<span style={{whiteSpace:"nowrap"}}>{metric.inflight}</span>}
                </Td>
                <Td/>
            </Tr>
        </Tbody>
        : <></>
    )

}