import React, {useEffect, useState} from 'react';
import {
    Bullseye,
    Button, EmptyState, EmptyStateHeader, EmptyStateIcon, EmptyStateVariant,
    Flex,
    FlexItem,
    Gallery, Label,
    Modal,
    PageSection, Spinner, Text, TextContent,
    TextInputGroup,
    TextInputGroupMain,
    TextInputGroupUtilities,
    ToggleGroup,
    ToggleGroupItem,
} from '@patternfly/react-core';
import './CatalogSelector.css';
import {shallow} from "zustand/shallow";
import TimesIcon from "@patternfly/react-icons/dist/esm/icons/times-icon";
import {CatalogCard} from "./CatalogCard";
import {useCatalogStore} from "./CatalogStore";
import {useDesignerStore} from "../../designer/DesignerStore";
import {CatalogProject} from "./CatalogModels";
import {CatalogApi} from "./CatalogApi";
import SearchIcon from "@patternfly/react-icons/dist/esm/icons/search-icon";
import {TALISMAN_PLATFORM} from "../TalismanUI";

export function CatalogSelector() {

    const [projects, setProjects, showSelector, setShowSelector, setShowCreateProjectModal, setSelectedProject]
        = useCatalogStore((s) => [s.projects, s.setProjects, s.showSelector, s.setShowSelector, s.setShowCreateProjectModal, s.setSelectedProject], shallow)
    const [dark] = useDesignerStore((s) => [s.dark], shallow)
    const [filter, setFilter] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [stable, setStable] = useState<boolean>(true);
    const [preview, setPreview] = useState<boolean>(true);
    const [module, setModule] = useState<boolean>(true);
    const [template, setTemplate] = useState<boolean>(true);
    const [example, setExample] = useState<boolean>(true);

    useEffect(() => {
        if (showSelector) {
            setLoading(true);
            setSelectedProject(undefined)
            CatalogApi.getCatalog(p => {
                setProjects(p);
                setLoading(false);
            })
        }
    }, [showSelector]);

    function selectProject(evt: React.MouseEvent, project: CatalogProject) {
        evt.stopPropagation();
        setFilter('');
        setShowSelector(false);
        setSelectedProject(project)
        setShowCreateProjectModal(true);
    }

    function searchInput() {
        return (
            <TextInputGroup>
                <TextInputGroupMain className="text-field" type="text" autoComplete={"off"}
                                    value={filter}
                                    onChange={(_, value) => setFilter(value)}/>
                <TextInputGroupUtilities>
                    <Button variant="plain" onClick={_ => setFilter('')}>
                        <TimesIcon/>
                    </Button>
                </TextInputGroupUtilities>
            </TextInputGroup>
        )
    }

    function close() {
        setFilter('');
        setShowSelector(false)
    }

    function getHeader(){
        return (
            <Flex direction={{default: "row"}}>
                <FlexItem style={{marginRight: '6px'}}>
                    <TextContent>
                        <Text component='h1'>Catalog</Text>
                    </TextContent>
                </FlexItem>
                <FlexItem flex={{default: "flex_2"}}>
                    {filteredProjects.length > 0 && <Label>{filteredProjects.length}</Label>}
                </FlexItem>
                <FlexItem>
                    <ToggleGroup aria-label="type">
                        <ToggleGroupItem text="Modules" key={'module'} buttonId="module" isSelected={module} onChange={_ => setModule(!module)}/>
                        <ToggleGroupItem text="Template" key={'template'} buttonId="template" isSelected={template} onChange={_ => setTemplate(!template)}/>
                        <ToggleGroupItem text="Examples" key={'example'} buttonId="example" isSelected={example} onChange={_ => setExample(!example)}/>
                    </ToggleGroup>
                </FlexItem>
                <FlexItem>
                    <ToggleGroup aria-label="supportLevel">
                        <ToggleGroupItem text="Stable" key={'stable'} buttonId="stable" isSelected={stable} onChange={_ => setStable(!stable)}/>
                        <ToggleGroupItem text="Preview" key={'preview'} buttonId="preview" isSelected={preview} onChange={_ => setPreview(!preview)}/>
                    </ToggleGroup>
                </FlexItem>
                <FlexItem>
                    {searchInput()}
                </FlexItem>
            </Flex>
        )
    }

    const filteredProjects = projects
        .filter(p => {
            if (module && p.type?.toLowerCase() === 'module') {
                return true;
            }
            if (example && p.type?.toLowerCase() === 'example') {
                return true;
            }
            if (template && p.type?.toLowerCase() === 'template') {
                return true;
            }
            return false;
        })
        .filter(p => {
            if (stable && p.supportLevel?.toLowerCase() === 'stable') {
                return true;
            }
            if (preview && p.supportLevel?.toLowerCase() === 'preview') {
                return true;
            }
            return false;
        })
        .sort((a,b) => {
            if (a.vendor === b.vendor) {
                return a.name.localeCompare(b.name);
            }
            if (a.vendor === TALISMAN_PLATFORM) {
                return -1;
            }
            if (b.vendor === TALISMAN_PLATFORM) {
                return 1;
            }
            return a.vendor.localeCompare(b.vendor)
        })

    return (
        <Modal
            aria-label='Catalog'
            width={'90%'}
            className='catalog-modal'
            isOpen={showSelector}
            onClose={() => close()}
            header={getHeader()}
            actions={{}}>
            {filteredProjects.length > 0 &&
                <PageSection padding={{default: "noPadding"}} variant={dark ? "darker" : "light"}>
                    <Gallery key={"gallery"} hasGutter className="catalog-gallery" minWidths={{default: '200px'}}>
                        {filteredProjects.map((project: CatalogProject) =>
                            <CatalogCard project={project} onProjectSelect={selectProject}/>
                        )}
                    </Gallery>
                </PageSection>
            }
            {filteredProjects.length === 0 &&
                <Bullseye>
                    {loading &&
                        <EmptyState variant={EmptyStateVariant.sm}>
                            <EmptyStateHeader titleText="Loading..."
                                              icon={<Spinner className="progress-stepper" diameter="80px" aria-label="Loading..."/>}
                                              headingLevel="h2"/>
                        </EmptyState>
                    }
                    {!loading &&
                        <EmptyState variant={EmptyStateVariant.sm}>
                            <EmptyStateHeader titleText="No projects found" icon={<EmptyStateIcon icon={SearchIcon}/>} headingLevel="h2"/>
                        </EmptyState>
                    }
                </Bullseye>
            }
        </Modal>
    )
}