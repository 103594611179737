
import React, {useState} from 'react';
import {
    Bullseye,
    Card,
    CardHeader, CardTitle, EmptyState, EmptyStateHeader, EmptyStateIcon, Flex, FlexItem,
    Spinner, Text, TextContent, TextInput, TextVariants, Toolbar, ToolbarContent, ToolbarItem,
} from '@patternfly/react-core';
import '../../../designer/karavan.css';
import {useAppConfigStore, useStatusesStore} from "../../../api/ProjectStore";
import {shallow} from "zustand/shallow";
import "../dashboard.css"
import {InnerScrollContainer, Table, TableVariant, Tbody, Td, Th, Thead, Tr} from "@patternfly/react-table";
import {IntegrationRow} from "./IntegrationRow";
import {IntegrationTotalRow} from "./IntegrationTotalRow";

export function CardIntegrations() {

    const [containers] = useStatusesStore((state) => [state.containers], shallow);
    const [selectedEnv] = useAppConfigStore((state) => [state.selectedEnv], shallow)
    const [filter, setFilter] = useState<string>('');

    function getTableBody() {
        return containerStatuses
            .filter(cs => selectedEnv.includes(cs.env))
            .filter(cs => cs.containerName.toLowerCase().includes(filter))
            .sort((a, b) => a.containerName < b.containerName ? -1 : 1)
            .map((cs, index) => <IntegrationRow key={index} index={index} container={cs}/>)
    }

    function getTitle() {
        return (
            <CardTitle>
                <Flex justifyContent={{default:"justifyContentSpaceBetween"}}>
                    <FlexItem>
                        <TextContent>
                            <Text component={TextVariants.h3}>Integrations</Text>
                        </TextContent>
                    </FlexItem>
                    <FlexItem>
                        <Toolbar id="toolbar-group-types">
                            <ToolbarContent>
                                <ToolbarItem>
                                    <TextInput className="text-field" type="search" id="search" name="search"
                                               autoComplete="off" placeholder="Search by name"
                                               value={filter}
                                               onChange={(_, e) => setFilter(e)}/>
                                </ToolbarItem>
                            </ToolbarContent>
                        </Toolbar>
                    </FlexItem>
                </Flex>
            </CardTitle>
        )
    }

    function getTableEmpty() {
        return (
            <Tbody>
                <Tr>
                    <Td colSpan={15}>
                        <Bullseye>
                            <EmptyState>
                                <EmptyStateHeader icon={<EmptyStateIcon icon={Spinner}/>}/>
                            </EmptyState>
                        </Bullseye>
                    </Td>
                </Tr>
            </Tbody>
        )
    }

    const containerStatuses = containers.filter(p => ['project','devmode'].includes(p.type));

    return (
        <Card className='projects-card'>
            <CardHeader>
                {getTitle()}
            </CardHeader>
            <InnerScrollContainer>
            <Table aria-label="Projects table" variant={TableVariant.compact} style={{borderTop:"1px solid var(--pf-v5-c-table--BorderColor)"}}>
                <Thead hasNestedHeader>
                    <Tr>
                        <Th modifier="fitContent" aria-label="empty"></Th>
                        <Th colSpan={1} hasRightBorder style={{textAlign:"center"}}>Project</Th>
                        <Th colSpan={2} hasRightBorder style={{textAlign:"center"}}>Container</Th>
                        <Th colSpan={3} hasRightBorder style={{textAlign:"center"}}>JVM</Th>
                        <Th colSpan={1} hasRightBorder style={{textAlign:"center"}}>Camel</Th>
                        <Th colSpan={6} style={{textAlign:"center"}}>Messages</Th>
                    </Tr>
                    <Tr>
                        <Th isSubheader modifier="truncate" aria-label="empty"></Th>
                        <Th isSubheader  modifier="truncate" textCenter={false} hasRightBorder>Name</Th>
                        <Th isSubheader  modifier="truncate" textCenter={true} >CPU</Th>
                        <Th isSubheader  modifier="truncate" textCenter={true} hasRightBorder>Memory</Th>
                        <Th isSubheader  modifier="truncate" textCenter={true} >Uptime</Th>
                        <Th isSubheader  modifier="truncate" textCenter={true} >CPU %</Th>
                        <Th isSubheader  modifier="truncate" textCenter={true} hasRightBorder>Memory</Th>
                        <Th isSubheader  modifier="truncate" textCenter={true} hasRightBorder>Context</Th>
                        <Th isSubheader  modifier="truncate" style={{textAlign: "right"}}>Total</Th>
                        <Th isSubheader  modifier="truncate" style={{textAlign: "right"}}>Succeeded</Th>
                        <Th isSubheader  modifier="truncate" style={{textAlign: "right"}}>Failed</Th>
                        <Th isSubheader  modifier="truncate" style={{textAlign: "right"}}>Failed diff</Th>
                        <Th isSubheader  modifier="truncate" style={{textAlign: "right"}}>Inflight</Th>
                        <Th isSubheader  modifier="fitContent" style={{textAlign: "right"}}>Topology</Th>
                    </Tr>
                </Thead>
                {containerStatuses && containerStatuses.length > 0 ? getTableBody() : getTableEmpty()}
                <IntegrationTotalRow key={'IntegrationTotalRow'}/>
            </Table>
            </InnerScrollContainer>
        </Card>
    )

}