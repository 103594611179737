import React, {useEffect, useRef} from "react";
import {KaravanApi} from "../api/KaravanApi";
import {
    Page,
} from "@patternfly/react-core";
import './main.css';
import {SsoApi} from "../api/SsoApi";
import {useAppConfigStore} from "../api/ProjectStore";
import {shallow} from "zustand/shallow";
import {PageNavigation} from "./PageNavigation";
import {useMainHook} from "./useMainHook";
import {Notification} from "../designer/utils/Notification";
import {MainRoutes} from "./MainRoutes";
import {NotificationApi} from "../api/NotificationApi";
import {BasicLoginPage} from "./BasicLoginPage";
import {ErrorEventBus} from "../api/ErrorEventBus";
import {MainLoader} from "./MainLoader";
import {MainApi} from "./MainApi";

export function Main() {

    const [readiness, setReadiness, isAuthorized, setAuthorized] =
        useAppConfigStore((s) => [s.readiness, s.setReadiness, s.isAuthorized, s.setAuthorized], shallow)
    const controllerRef = useRef(new AbortController());
    const {getData} = useMainHook();

    useEffect(() => {
        checkAuthType();
        // const interval = setInterval(() => resetNotification(), 60000);
        const sub = ErrorEventBus.onApiError()?.subscribe(err => {
            console.log("ApiError", err?.config?.url, err)
            if (err?.response?.status === 401 && KaravanApi.authType === 'basic') {
                window.location.reload();
            }
        });
        return () => {
            // clearInterval(interval);
            sub?.unsubscribe();
        };
    }, []);

    useEffect(() => {
        console.log("Main useEffect", readiness, isAuthorized, MainApi.getTalismanRoles())
        if (readiness && showMain()) {
            getData();
            resetNotification();
            KaravanApi.getMe(() => {})
        } else if (KaravanApi.authType === 'oidc') {
            SsoApi.auth(() => {
                KaravanApi.getMe((user: any) => {
                    setAuthorized(true);
                });
            });
        }
    }, [readiness, isAuthorized]);

    useEffect(() => {
        const interval = setInterval(() => KaravanApi.getReadiness(setReadiness), 10000)
        return () => clearInterval(interval);
    }, []);

    function resetNotification() {
        console.log("Notification fetcher reset");
        if (isAuthorized || KaravanApi.authType === 'public') {
            controllerRef.current.abort()
            const controller = new AbortController();
            controllerRef.current = controller;
            NotificationApi.notification(controller);
        }
    }

    function checkAuthType() {
        KaravanApi.getAuthType((authType: string) => {
            console.log("Main AuthType", authType);
        });
    }

    function showSpinner() {
        return KaravanApi.authType === undefined || readiness === undefined;
    }

    function showStepper() {
        return readiness !== undefined && readiness.status !== true;
    }

    function showMain() {
        return KaravanApi.authType !== undefined && readiness?.status === true && isAuthorized;
    }

    function isViewer(){
        return KaravanApi.authType !== 'public' &&
            (KaravanApi.me?.roles?.length === 0 || !KaravanApi.me?.roles?.includes('talisman-developer'));
    }

    function showBasicLogin(){
        return KaravanApi.authType === 'basic' && !isAuthorized && !showStepper() && !showSpinner();
    }

    return (
        <Page className={isViewer() ? "viewer-group karavan" : "karavan"}>
            {showBasicLogin() && <BasicLoginPage/>}
            {!showMain() && <MainLoader/>}
            {showMain() &&
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', gap:'0' , width: "100%", height: "100%"}}>
                    {<PageNavigation/>}
                    <div style={{height: "100%", flexGrow: '2'}}>
                        {<MainRoutes/>}
                    </div>
                </div>
            }
            <Notification/>
        </Page>
    );
};
