
import axios, {AxiosResponse} from "axios";
import {KaravanApi} from "../../api/KaravanApi";
import {AccessUser} from "./AccessModels";
import {Buffer} from "buffer";
import {useAppConfigStore} from "../../api/ProjectStore";
import {ErrorEventBus} from "../../api/ErrorEventBus";

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
const instance = KaravanApi.getInstance();

export class AccessApi {

    static accessUser?: any;

    static async login(username: string, password: string, after: (ok: boolean, res: any) => void) {
        instance.post('/login',
            {username: username, password: Buffer.from(password).toString('base64')},
            {headers: {'content-type': 'application/x-www-form-urlencoded'}})
            .then(res => {
                if (res.status === 200) {
                    const data = res.data;
                    AccessApi.accessUser = data.accessUser;
                    KaravanApi.basicToken = Buffer.from(username + ":" + data.key).toString('base64');
                    KaravanApi.setBasicAuthentication();
                    KaravanApi.getMe(user => {
                        after(true, res);
                        useAppConfigStore.setState({isAuthorized: true})
                    })
                } else if (res.status === 401) {
                    useAppConfigStore.setState({isAuthorized: false})
                    KaravanApi.basicToken = '';
                    after(false, res);
                }
            }).catch(err => {
            KaravanApi.basicToken = '';
            useAppConfigStore.setState({isAuthorized: false})
            after(false, err);
        });
    }

    static async getBrand(after: (config: any) => void) {
        axios.get('/login/brand', {headers: {'Accept': 'application/json'}})
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                }
            }).catch(err => {
            ErrorEventBus.sendApiError(err);
        });
    }

    static async logout() {
        instance.get('/logout')
            .then(res => {
                if (res.status === 200) {
                    useAppConfigStore.setState({isAuthorized: false})
                    KaravanApi.basicToken = '';
                }
            }).catch(err => {
            KaravanApi.basicToken = '';
            useAppConfigStore.setState({isAuthorized: false})
        });
    }

    static async getUser(username: string, after: (user: AccessUser) => void) {
        instance.get('/access/users/' + username)
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                } else {
                    after(res.data);
                }
            }).catch(err => {
            ErrorEventBus.sendApiError(err);
            after(err);
        });
    }

    static async putUser(user: AccessUser, after: (result: boolean, res: AxiosResponse<AccessUser> | any) => void) {
        try {
            instance.put('/access/users', user)
                .then(res => {
                    if (res.status === 200) {
                        after(true, res);
                    }
                }).catch(err => {
                after(false, err);
            });
        } catch (error: any) {
            after(false, error);
        }
    }

    static async putUserAsAdmin(user: AccessUser, after: (result: boolean, res: AxiosResponse<AccessUser> | any) => void) {
        try {
            instance.put('/admin/users', user)
                .then(res => {
                    if (res.status === 200) {
                        after(true, res);
                    }
                }).catch(err => {
                after(false, err);
            });
        } catch (error: any) {
            after(false, error);
        }
    }

    static async getUsers(after: (users: AccessUser[]) => void) {
        instance.get('/admin/users')
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                } else {
                    after([]);
                }
            }).catch(err => {
            ErrorEventBus.sendApiError(err);
            after([]);
        });
    }

    static async postUser(user: AccessUser, after: (result: boolean, res: AxiosResponse<AccessUser> | any) => void) {
        try {
            instance.post('/admin/users', user)
                .then(res => {
                    if (res.status === 200 || res.status === 201) {
                        after(true, res);
                    }
                }).catch(err => {
                after(false, err);
            });
        } catch (error: any) {
            after(false, error);
        }
    }

    static async deleteUser(username: string, after: (result: boolean, res: AxiosResponse<any> | any) => void) {
        try {
            instance.delete('/admin/users/' + username)
                .then(res => {
                    if (res.status === 202) {
                        after(true, res);
                    }
                }).catch(err => {
                after(false, err);
            });
        } catch (error: any) {
            after(false, error);
        }
    }
}
