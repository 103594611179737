
import axios, {AxiosResponse} from "axios";
import {JwtAccessUser} from "./JwtModels";
import {KaravanApi} from "../../api/KaravanApi";
import {ErrorEventBus} from "../../api/ErrorEventBus";
import {EventBus} from "../../designer/utils/EventBus";

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
const instance = KaravanApi.getInstance();

export class JwtApi {

    static async generateToken(username: string, after: (token: any) => void) {
        instance.get('/ui/jwt/token/' + username)
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                } else {
                    ErrorEventBus.sendApiError(res.status);
                    after(undefined);
                }
            }).catch(err => {
            ErrorEventBus.sendApiError(err);
            after(undefined);
        });
    }

    static async revokeToken(tokenId: string, after: (ok: boolean, res: AxiosResponse | any) => void) {
        instance.delete('/ui/jwt/token/' + tokenId)
            .then(res => {
                if (res.status === 200) {
                    after(true,  res);
                    EventBus.sendAlert('Success', 'Token revoked', 'success')
                } else {
                    ErrorEventBus.sendApiError(res.status);
                    after(false,  res);
                }
            }).catch(err => {
            ErrorEventBus.sendApiError(err);
            after(false, err);
        });
    }


    static async getUsers(after: (users: JwtAccessUser[]) => void) {
        instance.get('/ui/jwt/users')
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                } else {
                    after([]);
                }
            }).catch(err => {
            ErrorEventBus.sendApiError(err);
            after([]);
        });
    }

    static async postUser(user: JwtAccessUser, after: (result: boolean, res: AxiosResponse<JwtAccessUser> | any) => void) {
        try {
            instance.post('/ui/jwt/users', user)
                .then(res => {
                    if (res.status === 200 || res.status === 201) {
                        after(true, res);
                    }
                }).catch(err => {
                after(false, err);
            });
        } catch (error: any) {
            after(false, error);
        }
    }

    static async putUser(user: JwtAccessUser, after: (result: boolean, res: AxiosResponse<JwtAccessUser> | any) => void) {
        try {
            instance.put('/ui/jwt/users', user)
                .then(res => {
                    if (res.status === 200 || res.status === 201) {
                        after(true, res);
                        EventBus.sendAlert('Success', 'User updated', 'success')
                    }
                }).catch(err => {
                after(false, err);
            });
        } catch (error: any) {
            after(false, error);
        }
    }
}
