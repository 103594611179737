import {JwtApi} from "./JwtApi";
import {useJwtStore} from "./JwtStore";

export class JwtService {

    public static refreshUsers() {
        JwtApi.getUsers(users => {
            useJwtStore.setState({users: users});
        })
    }
}