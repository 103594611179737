import axios from "axios";
import {KaravanApi} from "../api/KaravanApi";
import {ErrorEventBus} from "../api/ErrorEventBus";

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
const instance = KaravanApi.getInstance();

export class PodEvent {
    id: string = '';
    containerName: string = '';
    reason: string = ''
    note: string = '';
    creationTimestamp: string = '';

    public constructor(init?: Partial<PodEvent>) {
        Object.assign(this, init);
    }
}

export class PodApi {

    static async getPodEvents(containerName: string, after: (podEvents: PodEvent[]) => void) {
        instance.get('/ui/kubernetes/' + containerName, {headers: {'Accept': 'application/json'}})
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                } else {
                    after([]);
                }
            }).catch(err => {
            ErrorEventBus.sendApiError(err);
            after([]);
        });
    }
}
