
import React, {useEffect} from "react";
import {
    Bullseye,
    EmptyState,
    EmptyStateVariant,
    EmptyStateHeader,
    EmptyStateIcon,
    EmptyStateBody
} from "@patternfly/react-core";
import NotAuthorizedIcon from "@patternfly/react-icons/dist/js/icons/user-secret-icon";
import {KaravanApi} from "../api/KaravanApi";
import {useNavigate} from "react-router-dom";
import {MainApi} from "./MainApi";

export function NotAuthorizedPage() {

    const navigate = useNavigate();

    useEffect(() => {
        const talismanRoles = MainApi.getTalismanRoles();
        const authorized = talismanRoles.length > 0;
        if (KaravanApi.authType !== 'public' && authorized) {
            navigate("/");
        }
    }, []);

    return (
        <Bullseye>
            <EmptyState variant={EmptyStateVariant.xl}>
                <EmptyStateHeader titleText="Not authorized" headingLevel="h4" icon={<EmptyStateIcon icon={NotAuthorizedIcon} />} />
                <EmptyStateBody>You are not authorize to use this application</EmptyStateBody>
            </EmptyState>
        </Bullseye>
    )
}
