
import React from 'react';
import {useStatusesStore} from "../../../api/ProjectStore";
import {shallow} from "zustand/shallow";
import {Chart, ChartDonut, ChartThemeColor} from "@patternfly/react-charts";
import "../dashboard.css"
import {useDockerInfoStore} from "../DashboardStore";
import {ContainerStatus} from "../../../api/ProjectModels";
import chart_color_blue_300 from "@patternfly/react-tokens/dist/esm/chart_color_blue_300";
import chart_color_blue_100 from "@patternfly/react-tokens/dist/esm/chart_color_blue_100";
import chart_color_green_300 from "@patternfly/react-tokens/dist/esm/chart_color_green_300";

export function CardDockerMemory() {

    const [MemTotal] = useDockerInfoStore((s) => [s.MemTotal], shallow)
    const [containers] = useStatusesStore((state) => [state.containers], shallow);

    function getGigabytes(bytes?: number): number {
        return bytes ? (bytes / 1024 / 1024 / 1024) : 0;
    }

    function getMemoryUsed(cs: ContainerStatus[]): number {
        const memories = cs.map(c => {
            const parts = c.memoryInfo?.split("/") || [];
            const mString = parts?.at(0) ||"0";
            return parseFloat(mString.replace(/[^0-9.]/g, ''));
        });

        const memory = memories && memories.length > 0 ? memories.reduce((n1, n2) => n1 + n2) : 0;
        return  getGigabytes(memory * 1024 * 1024);
    }

    const totalMemory = getGigabytes(MemTotal);
    const memoryUsed = getMemoryUsed(containers);
    const memoryProjects = getMemoryUsed(containers.filter(c => c.type === 'project'));
    const memoryOther = memoryUsed - memoryProjects;
    const freeMemory = totalMemory - memoryUsed;
    const memoryProjectsS = memoryProjects.toFixed(memoryProjects > 10 ? 1 : 1);
    const memoryOtherS =  memoryOther.toFixed(memoryProjects > 10 ? 1 : 1);
    const freeMemoryS =  freeMemory.toFixed(memoryProjects > 10 ? 1 : 1);
    return (
        <Chart
            ariaDesc="Messages"
            ariaTitle="Messages"
            legendData={[
                { name: `Integrations ${memoryProjectsS}` },
                { name: `Other ${memoryOtherS}` },
            ]}
            legendPosition="bottom"
            padding={{ bottom: 50, left: 0, right: 0, top: 0 }}
            minDomain={{y: 0}}
            width={250}
            height={150}
            themeColor={ChartThemeColor.multiOrdered}
            colorScale={[
                chart_color_blue_300.var,
                chart_color_blue_100.var,
                chart_color_green_300.var
            ]}
            showAxis={false}
        >
            <ChartDonut
                data={[
                    {name: 'Integrations', x: 'Integrations', y: memoryProjects},
                    {name: 'Other',x: 'Other', y: memoryOther},
                    {name: 'Free',x: 'Free', y: freeMemory}
                ]}
                title={`${memoryUsed.toFixed(1)}`}
                subTitle={`from ${totalMemory.toFixed(1)}`}
                name="memory"
            />
        </Chart>
    )

}