
import React from 'react';
import '../../designer/karavan.css';
import {TalismanPlatformVersion} from "../TalismanUI";
import {useAppConfigStore} from "../../api/ProjectStore";
import {shallow} from "zustand/shallow";

export function DashboardToolbar () {

    const [config] = useAppConfigStore((s) => [s.config], shallow)
    const titleSvg = config?.advanced.titleSvg ? config?.advanced.titleSvg : undefined;

    return (
        <div id="toolbar-group-types" style={{height: '65px'}}>
            {TalismanPlatformVersion(titleSvg)}
        </div>
    )

}