import React, {MouseEventHandler} from 'react';
import '../editor/DataEditor.css';
import { Button } from "@patternfly/react-core";
import AddIcon from "@patternfly/react-icons/dist/js/icons/plus-icon";
import DeleteIcon from "@patternfly/react-icons/dist/js/icons/times-circle-icon";
import EditIcon from "@patternfly/react-icons/dist/esm/icons/edit-icon";

export interface Props {
    tooltip: string
    onClick: MouseEventHandler<HTMLButtonElement> | undefined;
}

export function AddElementButton(props: Props) {

    return (
        <Button variant="plain" aria-label={props.tooltip} onClick={props.onClick}>
            <AddIcon/>
        </Button>
    )
}


export function EditElementButton(props: Props) {

    return (
        <Button variant="plain" aria-label={props.tooltip} onClick={props.onClick}>
            <EditIcon/>
        </Button>
    )
}

export function DeleteElementButton(props: Props) {

    return (
        <Button variant="link" isDanger aria-label={props.tooltip} onClick={props.onClick}>
            <DeleteIcon/>
        </Button>
    )
}