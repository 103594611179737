import * as React from 'react';
import {
    action,
    createTopologyControlButtons,
    defaultControlButtonsOptions,
    GRAPH_LAYOUT_END_EVENT,
    TopologyView,
    TopologyControlBar,
    Visualization,
    VisualizationProvider,
    VisualizationSurface,
    DagreLayout,
    ComponentFactory, ModelKind, withSelection, withPanZoom, GraphComponent,
} from '@patternfly/react-topology';
import {shallow} from "zustand/shallow";
import {getModel} from "../../../topology/TopologyApi";
import {KaravanApi} from "../../../api/KaravanApi";
import {ProjectFile} from "../../../api/ProjectModels";
import {useDashboardStore} from "../DashboardStore";
import {Skeleton} from "@patternfly/react-core";
import {useState} from "react";
import CustomGroup from "../../../topology/CustomGroup";
import CustomEdge from "../../../topology/CustomEdge";
import MetricNode from "./MetricNode";
import {IntegrationFile} from "karavan-core/lib/model/IntegrationDefinition";

export function TopologyPanel() {

    const [showTopology, setShowTopology, projectId, containerName] = useDashboardStore((s) =>
        [s.showTopology, s.setShowTopology, s.projectId, s.containerName], shallow);

    const [loaded, setLoaded] = useState<boolean>(false);

    const metricComponentFactory: ComponentFactory = (kind: ModelKind, type: string) => {
        switch (type) {
            case 'group':
                return withSelection()(CustomGroup);
            default:
                switch (kind) {
                    case ModelKind.graph:
                        return withPanZoom()(GraphComponent);
                    case ModelKind.node:
                        return (withSelection()(MetricNode));
                    case ModelKind.edge:
                        return (withSelection()(CustomEdge));
                    default:
                        return undefined;
                }
        }
    }

    const controller = React.useMemo(() => {
        console.log("Controller", projectId, showTopology);
        setLoaded(false);
        const newController = new Visualization();
        if (showTopology && projectId) {
            KaravanApi.getFiles(projectId, (files: ProjectFile[]) => {
                const ifiles = files.map(f => new IntegrationFile(f.name, f.code));
                const model = getModel(ifiles);

                newController.registerLayoutFactory((_, graph) =>
                    new DagreLayout(graph, {
                        rankdir: 'TB',
                        ranker: 'network-simplex',
                        nodesep: 20,
                        edgesep: 20,
                        ranksep: 0,
                    }));

                newController.registerComponentFactory(metricComponentFactory);

                newController.addEventListener(GRAPH_LAYOUT_END_EVENT, () => {
                    newController.getGraph().fit(80);
                });

                newController.fromModel(model, false);
                setLoaded(true);
            });
        }
        return newController;
    }, [showTopology, projectId]);


    const controlButtons = React.useMemo(() => {
        return createTopologyControlButtons({
            ...defaultControlButtonsOptions,
            zoomInCallback: action(() => {
                controller.getGraph().scaleBy(4 / 3);
            }),
            zoomOutCallback: action(() => {
                controller.getGraph().scaleBy(0.75);
            }),
            fitToScreenCallback: action(() => {
                controller.getGraph().fit(80);
            }),
            resetViewCallback: action(() => {
                controller.getGraph().reset();
                controller.getGraph().layout();
            }),
            legend: false,
        });
    }, [controller]);

    return (
        loaded ?
            <TopologyView
                className="topology-panel"
                controlBar={
                    <TopologyControlBar
                        controlButtons={controlButtons}
                    />
                }
            >
                <VisualizationProvider controller={controller}>
                    <VisualizationSurface state={{}}/>
                </VisualizationProvider>
            </TopologyView>
            : <Skeleton height="100%" screenreaderText="Loading..."/>
    )
}