
import React, {useEffect} from 'react';
import {
    PageSection,
    Text,
    TextContent, GridItem, Grid
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {MainToolbar} from "../../designer/MainToolbar";
import {useAppConfigStore} from "../../api/ProjectStore";
import {shallow} from "zustand/shallow";
import {DashboardToolbar} from "./DashboardToolbar";
import {CardIntegrations} from "./integrations/CardIntegrations";
import {ProjectService} from "../../api/ProjectService";
import {CardTop} from "./top/CardTop";
import {KaravanApi} from "../../api/KaravanApi";
import {useDockerInfoStore} from "./DashboardStore";
import {ProjectLogPanel} from "../../log/ProjectLogPanel";
import {DashboardService} from "./DashboardService";
import {JwtService} from "../jwt/JwtService";

export function DashboardPage() {

    const [config] = useAppConfigStore((state) => [state.config], shallow)
    const [setDockerInfo] = useDockerInfoStore((s) => [s.setDockerInfo], shallow)

    useEffect(() => {
        const interval = setInterval(() => {
            refreshContainer();
        }, 1300)
        return () => clearInterval(interval);
    }, []);

    function refreshContainer() {
        ProjectService.refreshProjects();
        ProjectService.refreshAllDeploymentStatuses();
        ProjectService.refreshAllContainerStatuses();
        DashboardService.refreshAllHealth();
        DashboardService.refreshAllMetrics();
        DashboardService.refreshAllMetricsHistory();
        KaravanApi.getInfrastructureInfo(info => {
            if (config.infrastructure === 'docker') {
                setDockerInfo(info.Architecture, info.ServerVersion, info.MemTotal, info.NCPU, info.Containers, info.ContainersPaused, info.ContainersRunning, info.ContainersStopped)
            }
        })
    }

    function title() {
        return (<TextContent>
            <Text component="h2">Dashboard</Text>
        </TextContent>);
    }

    return (
        <PageSection className="kamelet-section dashboard-page" padding={{default: 'noPadding'}}>
            <PageSection className="tools-section" padding={{default: 'noPadding'}}>
                <MainToolbar title={title()} tools={<DashboardToolbar/>}/>
            </PageSection>
            <PageSection isFilled>
                <Grid hasGutter>

                    <GridItem span={12}>
                        <CardTop/>
                    </GridItem>

                    <GridItem span={12}>
                        <CardIntegrations/>
                    </GridItem>

                    {/*<GridItem span={2} rowSpan={3}>*/}
                    {/*    <CardInfrastructureInfo/>*/}
                    {/*</GridItem>*/}

                </Grid>
            </PageSection>
            <ProjectLogPanel/>
        </PageSection>
    )

}