import React from 'react';
import {
    Badge, capitalize,
    Card, CardBody, CardFooter,
    CardHeader,
    Text, Tooltip, TooltipPosition,
} from '@patternfly/react-core';
import './CatalogSelector.css';
import {CatalogProject} from "./CatalogModels";
import TalismanLogo from "../../main/Logo";
import CatalogIcon from "@patternfly/react-icons/dist/esm/icons/catalog-icon";
import {TALISMAN_PLATFORM} from "../TalismanUI";

interface Props {
    project: CatalogProject,
    onProjectSelect: (evt: React.MouseEvent, project: CatalogProject) => void
}

export function CatalogCard (props: Props) {

    function selectProject(evt: React.MouseEvent, project: CatalogProject) {
        props.onProjectSelect(evt, project);
    }

    const {project} = props;
    const projectId = project.projectId;
    const catalog = project.catalog;
    const supportLevel = project.supportLevel;
    const name = project.name;
    const description = project.description;
    const type = project.type;
    const vendor = project.vendor;
    const isTalisman = vendor === TALISMAN_PLATFORM;
    const isModule = type === 'module';
    return (
        <Card key={projectId + ":" + catalog} isCompact isPlain isFlat isRounded className="catalog-card"
              onClick={event => selectProject(event, project)}>
            <CardHeader className="header-labels">
                <Badge isRead={supportLevel?.toLowerCase() !== 'stable'} className="support-level labels">{capitalize(supportLevel)}</Badge>
                <Badge isRead={!isModule} className="labels">{capitalize(type)}</Badge>
            </CardHeader>
            <CardHeader className="header-name">
                <div>
                    {isTalisman ? TalismanLogo("catalog-logo") : <CatalogIcon className="catalog-logo"/>}
                </div>
                <Text className='catalog-card-title'>{name}</Text>
            </CardHeader>
            <CardBody>
                <Tooltip content={description} position={TooltipPosition.bottom} entryDelay={1000}>
                    <Text className="pf-v5-u-color-200">{description}</Text>
                </Tooltip>
            </CardBody>
            <CardFooter className="footer-labels">
                <Badge isRead={!isTalisman} className={isTalisman ? "talisman-color labels" : "labels"}>{capitalize(vendor)}</Badge>
            </CardFooter>
        </Card>
    )
}