import {createWithEqualityFn} from "zustand/traditional";
import {shallow} from "zustand/shallow";
import {CatalogProject} from "./CatalogModels";

interface CatalogState {
    projects: CatalogProject[];
    setProjects: (projects: CatalogProject[]) => void;
    showSelector: boolean;
    setShowSelector: (showSelector: boolean) => void;
    selectedProject?: CatalogProject;
    setSelectedProject: (selectedProject?: CatalogProject) => void;
    showCreateProjectModal: boolean;
    setShowCreateProjectModal: (showCreateProjectModal: boolean) => void;
}

export const useCatalogStore = createWithEqualityFn<CatalogState>((set) => ({
    projects: [],
    setProjects: (projects: CatalogProject[])=> {
        set({projects: projects});
    },
    showSelector: false,
    setShowSelector: (showSelector: boolean) => {
        set({showSelector: showSelector})
    },
    showCreateProjectModal: false,
    setShowCreateProjectModal: (showCreateProjectModal: boolean) => {
        set({showCreateProjectModal: showCreateProjectModal})
    },
    setSelectedProject: (selectedProject?: CatalogProject) => {
        set({selectedProject: selectedProject})
    },
}), shallow)


