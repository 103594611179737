
import React from 'react';
import '../../../designer/karavan.css';
import {useStatusesStore} from "../../../api/ProjectStore";
import {shallow} from "zustand/shallow";
import {Chart, ChartDonut, ChartThemeColor} from "@patternfly/react-charts";
import "../dashboard.css"
import chart_color_green_300 from "@patternfly/react-tokens/dist/esm/chart_color_green_300";

export const chart_color_grey_100 = {
    "name": "--pf-v5-chart-color-grey-100",
    "value": "#B8BBBE",
    "var": "var(--pf-v5-chart-color-grey-100, #B8BBBE)"
};

export function CardIntegrations() {

    const [containers] = useStatusesStore((state) => [state.containers], shallow);

    const containerStatuses = containers.filter(p => ['project','devmode'].includes(p.type));
    const countTotal = containerStatuses.length;
    const countRunning = containerStatuses.filter(c => c.state === 'running').length;
    const countDown = countTotal - countRunning;

    return (
        <Chart
            ariaDesc="Containers"
            ariaTitle="Containers"
            width={250}
            height={150}
            showAxis={false}
            padding={{bottom: 50, left: 0, right: 0, top: 0}}
            legendData={[{name: `Running: ${countRunning}`}, {name: `Stopped: ${countDown}`}]}
            legendPosition="bottom"
            themeColor={ChartThemeColor.multiOrdered}
            colorScale={[
                chart_color_green_300.var,
                chart_color_grey_100.var
            ]}
        >
            <ChartDonut
                title={`${countRunning}`}
                subTitle={` from ${countTotal}`}
                constrainToVisibleArea
                data={[{x: 'Running', y: countRunning}, {x: '', y: countDown}]}
                name="containers"
                labels={({datum}) => `Running ${countRunning}`}
            />
        </Chart>
    )

}