import {AccessApi} from "./AccessApi";
import {useAccessStore} from "./AccessStore";

export class AccessService {

    public static refreshUsers() {
        AccessApi.getUsers(users => {
            useAccessStore.setState({users: users});
        })
    }
}