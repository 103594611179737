import React, {useEffect, useState} from 'react';
import {
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    TextInput,
    Button,
} from '@patternfly/react-core';
import PlusIcon from '@patternfly/react-icons/dist/esm/icons/plus-icon';
import CatalogIcon from '@patternfly/react-icons/dist/esm/icons/catalog-icon';
import {shallow} from "zustand/shallow";
import RefreshIcon from "@patternfly/react-icons/dist/esm/icons/sync-alt-icon";
import {useAppConfigStore, useProjectsStore, useProjectStore} from "../../api/ProjectStore";
import {ProjectService} from "../../api/ProjectService";
import {Project} from "../../api/ProjectModels";
import {useCatalogStore} from "../catalog/CatalogStore";
import {CatalogSelector} from "../catalog/CatalogSelector";
import {CatalogApi} from "../catalog/CatalogApi";
import {CreateProjectFromCatalogModal} from "../catalog/CreateProjectFromCatalogModal";

export function ProjectsToolbarWithCatalog() {

    const [showSelector, setShowSelector, showCreateProjectModal]
        = useCatalogStore((s) => [s.showSelector, s.setShowSelector, s.showCreateProjectModal], shallow)
    const [filter, setFilter] = useProjectsStore((s) => [s.filter, s.setFilter], shallow)
    const [setProject] = useProjectStore((s) => [s.setProject], shallow)
    const [showCatalog, setShowCatalog] = useState<boolean>(false);

    const [config] = useAppConfigStore((s) => [s.config], shallow);
    const isDev = config.environment === 'dev';

    useEffect(() => {
        CatalogApi.getCatalog(p => {
            setShowCatalog(p.length > 0);
        })
    }, []);

    return (
        <Toolbar id="toolbar-group-types">
            <ToolbarContent>
                <ToolbarItem>
                    <Button icon={<RefreshIcon/>}
                            variant={"link"}
                            onClick={e => ProjectService.refreshProjects()}
                    />
                </ToolbarItem>
                <ToolbarItem>
                    <TextInput className="text-field" type="search" id="search" name="search"
                               autoComplete="off" placeholder="Search by name"
                               value={filter}
                               onChange={(_, e) => setFilter(e)}/>
                </ToolbarItem>
                {isDev && showCatalog &&
                    <ToolbarItem>
                        <Button className="dev-action-button" variant="secondary"
                                icon={<CatalogIcon/>}
                                onClick={e => setShowSelector(true)}>
                            Catalog
                        </Button>
                    </ToolbarItem>
                }
                {isDev && <ToolbarItem>
                    <Button className="dev-action-button" variant="primary"
                            icon={<PlusIcon/>}
                            onClick={e => setProject(new Project(), 'create')}>
                        Create
                    </Button>
                </ToolbarItem>}
            </ToolbarContent>
            {showSelector && <CatalogSelector/>}
            {showCreateProjectModal && <CreateProjectFromCatalogModal/>}
        </Toolbar>
    )
}