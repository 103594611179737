import React from 'react';
import {Badge, Button, Flex, FlexItem, Label, Tooltip} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {Td, Tr} from "@patternfly/react-table";
import DeleteIcon from "@patternfly/react-icons/dist/js/icons/times-icon";
import CopyIcon from "@patternfly/react-icons/dist/esm/icons/copy-icon";
import DownloadIcon from "@patternfly/react-icons/dist/esm/icons/download-icon";
import {shallow} from "zustand/shallow";
import {useNavigate} from "react-router-dom";
import {Project} from "../../api/ProjectModels";
import {useAppConfigStore, useLogStore, useProjectStore, useStatusesStore} from "../../api/ProjectStore";
import {ProjectZipApi} from "./ProjectZipApi";
import FileSaver from "file-saver";

interface Props {
    project: Project
}

export function ProjectsTableRowWithCatalog (props: Props) {

    const [deployments, containers] = useStatusesStore((state) => [state.deployments, state.containers], shallow)
    const {config} = useAppConfigStore();
    const [setProject] = useProjectStore((state) => [state.setProject, state.setOperation], shallow);
    const [setShowLog] = useLogStore((state) => [state.setShowLog], shallow);
    const navigate = useNavigate();

    function getEnvironments(): string [] {
        return config.environments && Array.isArray(config.environments) ? Array.from(config.environments) : [];
    }

    function getStatusByEnvironments(name: string): [string, any] [] {
        return getEnvironments().map(e => {
            const env: string = e as string;
            const status = config.infrastructure === 'kubernetes'
                ? deployments.find(d => d.projectId === name && d.env === env)
                : containers.find(d => d.projectId === name && d.containerName === name && d.env === env && d.state === 'running');
            return [env, status != null];
        });
    }

    function downloadProject(projectId: string) {
        ProjectZipApi.downloadZip(projectId, data => {
            console.log(data);
            // const f = new File([data], projectId + ".zip", {type: 'application/zip'});
            // console.log(f)
            // FileSaver.saveAs(f);
            // const blob = new Blob([data], {type: "application/octet-stream"});
            // console.log(blob);
            // FileSaver.saveAs(blob, projectId + '.zip');
        });
    }

    const project = props.project;
    const isBuildIn = ['kamelets', 'templates'].includes(project.projectId);
    const commit = project.lastCommit ? project.lastCommit?.substr(0, 7) : undefined;
    return (
        <Tr key={project.projectId}>
            <Td>
                <Button style={{padding: '6px'}} variant={"link"} onClick={e => {
                    // setProject(project, "select");
                    setShowLog(false, 'none');
                    // ProjectEventBus.selectProject(project);
                    navigate("/projects/"+ project.projectId);
                }}>
                    {project.projectId}
                </Button>
            </Td>
            <Td>{project.name}</Td>
            <Td isActionCell>
                {commit && <Tooltip content={project.lastCommit} position={"bottom"}>
                    <Badge className="badge">{commit}</Badge>
                </Tooltip>}
            </Td>
            <Td noPadding>
                {!isBuildIn &&
                    <div style={{display: "flex", gap:"2px"}}>
                        {getStatusByEnvironments(project.projectId).map(value => {
                            const active = value[1];
                            const color = active ? "green" : "grey"
                            const style = active ? {fontWeight: "bold"} : {}
                            return <Label key={value.toString()} style={style} color={color} >{value[0]}</Label>
                        })}
                    </div>
                }
            </Td>
            <Td className="project-action-buttons">
                {!isBuildIn &&
                    <Flex direction={{default: "row"}} justifyContent={{default: "justifyContentFlexEnd"}}
                          spaceItems={{default: 'spaceItemsNone'}}>
                        <FlexItem>
                            <Tooltip content={"Copy project"} position={"bottom"}>
                                <Button className="dev-action-button" variant={"plain"} icon={<CopyIcon/>}
                                        onClick={e => {
                                            setProject(project, "copy");
                                        }}></Button>
                            </Tooltip>
                        </FlexItem>
                        <FlexItem>
                            <Tooltip content={"Delete project"} position={"bottom"}>
                                <Button className="dev-action-button" variant={"plain"} icon={<DeleteIcon/>} onClick={e => {
                                    setProject(project, "delete");
                                }}></Button>
                            </Tooltip>
                        </FlexItem>
                        {/*<FlexItem>*/}
                        {/*    <Tooltip content={"Download project"} position={"bottom-end"}>*/}
                        {/*        <Button className="dev-action-button" variant={"plain"} icon={<DownloadIcon/>}*/}
                        {/*                onClick={e => {*/}
                        {/*                    downloadProject(project.projectId);*/}
                        {/*                }}></Button>*/}
                        {/*    </Tooltip>*/}
                        {/*</FlexItem>*/}
                    </Flex>
                }
            </Td>
        </Tr>
    )
}