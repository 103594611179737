
import axios from "axios";
import {Health, Metric, MetricHistory} from "./DashboardModels";
import {KaravanApi} from "../../api/KaravanApi";
import {ErrorEventBus} from "../../api/ErrorEventBus";

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
const instance = KaravanApi.getInstance();

export class DashboardApi {

    static async getMetrics(after: (metrics: Metric[]) => void) {
        instance.get('/ui/metric', {headers: {'Accept': 'application/json'}})
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                } else {
                    after([]);
                }
            }).catch(err => {
            ErrorEventBus.sendApiError(err);
            after([]);
        });
    }

    static async getMetricsHistory(after: (metrics: MetricHistory[]) => void) {
        instance.get('/ui/metric/history', {headers: {'Accept': 'application/json'}})
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                } else {
                    after([]);
                }
            }).catch(err => {
            ErrorEventBus.sendApiError(err);
            after([]);
        });
    }

    static async getHealths(after: (healths: Health[]) => void) {
        instance.get('/ui/health', {headers: {'Accept': 'application/json'}})
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                } else {
                    after([]);
                }
            }).catch(err => {
            ErrorEventBus.sendApiError(err);
            after([]);
        });
    }
}
