import React, {useEffect} from 'react';
import {
    Alert,
    Button,
    Form, FormAlert, FormGroup, FormHelperText, HelperText, HelperTextItem,
    Modal,
    ModalVariant, Switch, TextInput,
} from '@patternfly/react-core';
import {SubmitHandler, useForm} from "react-hook-form";
import {AxiosResponse} from "axios";
import {useFormUtil} from "../../util/useFormUtil";
import {EventBus} from "../../designer/utils/EventBus";
import {shallow} from "zustand/shallow";
import {JwtAccessUser} from "./JwtModels";
import {JwtApi} from "./JwtApi";
import {useJwtStore} from "./JwtStore";

export function JwtUserModal() {

    const [openModal, setOpenModal, currentUser, users]
        = useJwtStore((s) => [s.openModal, s.setOpenModal, s.currentUser, s.users], shallow);
    const [isReset, setReset] = React.useState(false);
    const [changePassword, setChangePassword] = React.useState(false);
    const [backendError, setBackendError] = React.useState<string>();
    const formContext = useForm<JwtAccessUser>({mode: "all"});
    const {getTextField, getPasswordField} = useFormUtil(formContext);
    const {
        formState: {errors},
        handleSubmit,
        reset,
        trigger
    } = formContext;

    useEffect(() => {
        if (isNewUser()) {
            reset(new JwtAccessUser());
            setChangePassword(true);
        } else {
            reset(currentUser);
            setChangePassword(false);
        }
        setBackendError(undefined);
        setReset(true);
    }, [reset]);

    useEffect(() => {
        isReset && trigger();
    }, [trigger, isReset]);

    function closeModal() {
        setOpenModal(false)
    }

    const onSubmit: SubmitHandler<JwtAccessUser> = (data) => {
        if (isNewUser()) {
            JwtApi.postUser(data, after)
        } else {
            JwtApi.putUser(data, after)
        }
    }

    function after (result: boolean, res: AxiosResponse<JwtAccessUser> | any) {
        if (result) {
            onSuccess(res.data);
        } else {
            const data = res?.response?.data;
            const error = data && data !== '' ? data : res?.message;
            setBackendError(error);
        }
    }

    function onSuccess (user: JwtAccessUser) {
        const message = `User ${user.username} successfully ` + (isNewUser() ? "created" : "updated");
        EventBus.sendAlert( "Success", message, "success");
        closeModal();
    }

    function onKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
        if (event.key === 'Enter') {
            handleSubmit(onSubmit)()
        }
    }

    function isValidUsername(input: string): boolean {
        const pattern = /^[a-z][a-z0-9-]*$/;
        return pattern.test(input);
    }

    function isValidEmail(input: string): boolean {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(input);
    }

    function arePasswordsEqual() {
        const pwd1 = formContext.getValues('password');
        const pwd2 = formContext.getValues('password2');
        return pwd1 === pwd2;
    }

    function canNotSubmit() {
        if (changePassword) {
            return Object.getOwnPropertyNames(errors).length > 0 || !arePasswordsEqual();
        } else {
            return Object.getOwnPropertyNames(errors).length > 0;
        }
    }

    function isNewUser() {
        return currentUser === undefined;
    }

    function getPasswordError() {
        if (!arePasswordsEqual()) {
            return (<FormGroup>
                <FormHelperText>
                    <HelperText>
                        <HelperTextItem variant={'error'}>
                            Passwords should be equal!
                        </HelperTextItem>
                    </HelperText>
                </FormHelperText>
            </FormGroup>)
        }
    }

    return (
        <Modal
            title={isNewUser() ? "Create JWT User" : "Update JWT User"}
            variant={ModalVariant.small}
            isOpen={openModal}
            onClose={closeModal}
            onKeyDown={onKeyDown}
            actions={[
                <Button key="confirm" variant="primary"
                        onClick={handleSubmit(onSubmit)}
                        isDisabled={canNotSubmit()}
                >
                    Save
                </Button>,
                <Button key="cancel" variant="secondary" onClick={closeModal}>Cancel</Button>
            ]}
            className="new-project"
        >
            <Form isHorizontal={true} autoComplete="off">
                {!isNewUser() &&
                    <FormGroup label="Username" fieldId='username' isRequired>
                        <TextInput className="text-field" type="text" id='username' value={currentUser?.username} isDisabled/>
                    </FormGroup>
                }
                {isNewUser() && getTextField('username', 'Username', {
                    regex: v => isValidUsername(v) || 'Only lowercase characters, numbers and dashes allowed',
                    length: v => v.length > 3 || 'Username should be longer that 3 characters',
                    name: v => !isNewUser() || !users.map(u => u.username).includes(v) || "User already exists!s",
                })}
                {getTextField('firstName', 'First Name', {
                    length: v => v.length > 0 || 'First name should not be empty',
                })}
                {getTextField('lastName', 'Last Name', {
                    length: v => v.length > 0 || 'Last name should not be empty',
                })}
                {getTextField('email', 'Email', {
                    length: v => v.length > 0 || 'Last name should not be empty',
                    email: v => isValidEmail(v) || 'Invalid email'
                }, 'email')}
                {!isNewUser() &&
                    <FormGroup label="Change password" fieldId='switch'>
                        <Switch isChecked={changePassword} onChange={(_, checked) => setChangePassword(checked)}/>
                    </FormGroup>
                }
                {changePassword && getPasswordField('password', 'Password', {})}
                {changePassword && getPasswordField('password2', 'Retype Password', {})}
                {changePassword && getPasswordError()}
                {backendError &&
                    <FormAlert>
                        <Alert variant="danger" title={backendError} aria-live="polite" isInline />
                    </FormAlert>
                }
            </Form>
        </Modal>
    )
}