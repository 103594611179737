import {ChartThemeColor, getCustomTheme,} from "@patternfly/react-charts";
import chart_color_green_300 from '@patternfly/react-tokens/dist/esm/chart_color_green_300';
import chart_color_red_300 from '@patternfly/react-tokens/dist/esm/chart_color_red_100';
import chart_color_blue_300 from '@patternfly/react-tokens/dist/esm/chart_color_blue_300';

export function CustomTheme() {

    // Colors
    const colorScale = [
        chart_color_blue_300.var,
        chart_color_red_300.var,
        chart_color_green_300.var,
    ];

    const themeProps = {
        bar: {
            colorScale: colorScale,
        },
        donat: {
            colorScale: colorScale,
        },
        chart: {
            colorScale: colorScale,
        },
        group: {
            colorScale: colorScale,
        },
        legend: {
            colorScale: colorScale,
        }
    };

    // Applies theme color and variant to base theme
    return getCustomTheme(
        ChartThemeColor.multiOrdered,
        themeProps
    )
}

export function CustomTheme2Red() {

    // Colors
    const colorScale = [
        chart_color_blue_300.var,
        chart_color_red_300.var,
        chart_color_red_300.var,
    ];

    const themeProps = {
        bar: {
            colorScale: colorScale,
        },
        donat: {
            colorScale: colorScale,
        },
        chart: {
            colorScale: colorScale,
        },
        group: {
            colorScale: colorScale,
        },
        legend: {
            colorScale: colorScale,
        }
    };

    // Applies theme color and variant to base theme
    return getCustomTheme(
        ChartThemeColor.multiOrdered,
        themeProps
    )
}