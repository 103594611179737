
import axios from "axios";
import {KaravanApi} from "../../api/KaravanApi";
import {ErrorEventBus} from "../../api/ErrorEventBus";
import FileSaver, {saveAs} from "file-saver";

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
const instance = KaravanApi.getInstance();


export class ProjectZipApi {

    static accessUser?: any;

    static async downloadZip(projectId: string, after: (res: any) => void) {
        instance.get('/ui/zip/project/' + projectId,
            {headers: {
                'Accept': 'application/octet-stream',
                // 'Content-Type': 'application/octet-stream',
                responseType: 'blob'
            }}).then(response => {
                console.log(response)
            FileSaver.saveAs(response.data, projectId + ".zip");
            }).catch(err => {
            ErrorEventBus.sendApiError(err);
            after({});
        });
    }
}
