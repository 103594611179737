import React from 'react';
import {
    Chart, ChartArea, ChartAxis,
    ChartGroup, ChartStack, ChartThemeColor, ChartVoronoiContainer
} from "@patternfly/react-charts";
import {shallow} from "zustand/shallow";
import {useMetricStore} from "../DashboardStore";
import {merge} from "../DashboardFunc";
import {getMegabytes} from "../../../util/StringUtils";

export interface Props {
    containerName?: string
    showAxis?: boolean
    hideLegend?: boolean
}

export function CardJVM(props: Props) {

    const [history] = useMetricStore((s) => [s.history], shallow)


    let heap: number[] = [0]
    let nonHeap: number[] = [0]

    const cName = props.containerName;
    history.filter(m => cName === undefined || m.containerName === cName).forEach(h => {
        const m = h.metrics;
        const i = m ? m.map(x => getMegabytes(x.usedHeap)) : [];
        heap = merge(heap, i);
        const f = m ? m.map(x => getMegabytes(x.usedNonHeap)) : [];
        nonHeap = merge(nonHeap, f);
    })

    const lastH = (heap.length > 1 ? heap.at(heap.length - 1) : 0) || 0;
    const lastN = (nonHeap.length > 1 ? nonHeap.at(nonHeap.length - 1) : 0) || 0;
    const legend = props.hideLegend !== true ? [{name: `Heap: ${lastH.toFixed(0)}`},
        {name: `NonHeap: ${lastN.toFixed(0)}`}] : undefined;
    const padding = props.hideLegend !== true ?  {bottom: 50, left: 0, right: 0, top: 10} : {bottom: 10, left: 45, right: 10, top: 40};

    return (
        <Chart
            ariaDesc="JVM"
            ariaTitle="JVM"
            containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.y?.toFixed(2)}`}  />}
            legendData={legend}
            legendPosition={"bottom"}
            minDomain={{y: 0}}
            width={250}
            height={150}
            padding={padding}
            themeColor={ChartThemeColor.multiOrdered}
            showAxis={props.showAxis === true}
        >
            <ChartAxis style={{axis: {strokeWidth: 0}, tickLabels: {fontSize: 0}, ticks: {strokeWidth: 0}}} />
            <ChartAxis dependentAxis style={{axis: {strokeWidth: 1}, tickLabels: {fontSize: 8}, ticks: {strokeWidth: 1}}}/>
            <ChartGroup style={{data: {strokeWidth: ({data}) => 1}}}>
                <ChartStack>
                    <ChartArea
                        data={heap.map((v, i) => {
                            return { name: 'heap', x: i, y: v }
                        })}
                        interpolation="monotoneX"
                        name="heap"
                    />
                    <ChartArea
                        data={nonHeap.map((v, i) => {
                            return { name: 'nonHeap', x: i, y: v }
                        })}
                        interpolation="monotoneX"
                        name="nonHeap"
                    />
                </ChartStack>
            </ChartGroup>
        </Chart>
    )

}