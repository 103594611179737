import React, {useEffect} from 'react';
import {shallow} from "zustand/shallow";
import {Flex, FlexItem} from "@patternfly/react-core";
import {useDocumentEditorStore} from "./DocumentStore";
import {OpenApiTree} from "./OpenApiTree";
import {useFileStore} from "../api/ProjectStore";
import {ProjectService} from "../api/ProjectService";

export function OpenApiDesigner() {

    const [fileName, code] = useDocumentEditorStore((s) =>
        [s.fileName, s.code], shallow);
    const [file] = useFileStore((s) => [s.file], shallow)

    useEffect(() => {
        const interval = setInterval(() => {
            saveCode();
        }, 700);
        return () => {
            clearInterval(interval);
            saveCode();
        }
    }, [code]);

    function saveCode() {
        if (fileName && fileName === file?.name && code && file?.code !== code) {
            file.code = code;
            ProjectService.updateFile(file, true);
        }
    }

    return (
        <Flex direction={{default: 'row'}} gap={{default: 'gapNone'}} justifyContent={{default: 'justifyContentSpaceBetween'}} style={{height:'100%', backgroundColor: 'white'}}>
            <FlexItem flex={{default: 'flex_1'}} style={{height:'100%', overflowY: 'auto', paddingBottom: '100px'}}>
                <OpenApiTree/>
            </FlexItem>
        </Flex>
    );
}
