/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    Label,
    PageSection, ToggleGroup, ToggleGroupItem,
    Tooltip,
    TooltipPosition
} from '@patternfly/react-core';
import './ProjectLog.css';
import CloseIcon from '@patternfly/react-icons/dist/esm/icons/times-icon';
import ExpandIcon from '@patternfly/react-icons/dist/esm/icons/expand-icon';
import CollapseIcon from '@patternfly/react-icons/dist/esm/icons/compress-icon';
import CleanIcon from '@patternfly/react-icons/dist/esm/icons/trash-alt-icon';
import {useAppConfigStore, useLogStore} from "../api/ProjectStore";
import {shallow} from "zustand/shallow";
import {ProjectEventBus} from "../api/ProjectEventBus";
import {ProjectLog} from "./ProjectLog";
import {LogWatchApi} from "../api/LogWatchApi";
import {PodEventsLog} from "./PosEventsLog";

const INITIAL_LOG_HEIGHT = "50%";

export function ProjectLogPanel() {

    const [config] = useAppConfigStore((state) => [state.config], shallow);
    const [showLog, type, setShowLog, podName] = useLogStore(
        (state) => [state.showLog, state.type, state.setShowLog, state.podName], shallow)

    const [height, setHeight] = useState(INITIAL_LOG_HEIGHT);
    const [isTextWrapped, setIsTextWrapped] = useState(true);
    const [autoScroll, setAutoScroll] = useState(true);
    const [currentPodName, setCurrentPodName] = useState<string | undefined>(undefined);
    const [tab, setTab] = useState<'log' | 'events'>('log');
    const [controller, setController] = React.useState(new AbortController());

    useEffect(() => {
        setTab('log');
        controller.abort()
        const c = new AbortController();
        setController(c);
        if (showLog && type !== 'none' && podName !== undefined) {
            const f = LogWatchApi.fetchData(type, podName, c).then(value => {
                console.log("Fetch Started for: " + podName);
            });
        }
        return () => {
            c.abort();
        };
    }, [showLog, type, podName]);

    useEffect(() => {
        if (currentPodName !== podName) {
            ProjectEventBus.sendLog("set", "");
            setCurrentPodName(podName);
        }
    }, [podName]);

    function getButtons() {
        const isKubernetes = config.infrastructure === 'kubernetes';
        const title = isKubernetes ? ('Pod (' + type + "): " + podName) : (type + ": " + podName);
        return (
            <div className="buttons">
                <Label className="log-name">{podName !== undefined ? title : ''}</Label>
                <ToggleGroup isCompact style={{marginRight:'auto'}}>
                    <ToggleGroupItem key={0} isSelected={tab === 'log'} text='Log' onChange={_ => setTab('log')}/>
                    {isKubernetes &&
                        <ToggleGroupItem key={1} isSelected={tab === 'events'} text='Events' onChange={_ => setTab('events')}/>
                    }
                </ToggleGroup>
                <Tooltip content={"Clean log"} position={TooltipPosition.bottom}>
                    <Button variant="plain" onClick={() => ProjectEventBus.sendLog('set', '')} icon={<CleanIcon/>}/>
                </Tooltip>
                <Checkbox label="Wrap text" aria-label="wrap text checkbox" isChecked={isTextWrapped}
                          id="wrap-text-checkbox"
                          onChange={(_, checked) => setIsTextWrapped(checked)}/>
                <Checkbox label="Autoscroll" aria-label="autoscroll checkbox" isChecked={autoScroll}
                          id="autoscroll-checkbox"
                          onChange={(_, checked) => setAutoScroll(checked)}/>
                <Tooltip content={height === "100%" ? "Collapse" : "Expand"} position={TooltipPosition.bottom}>
                    <Button variant="plain" onClick={() => {
                        const h = height === "100%" ? INITIAL_LOG_HEIGHT : "100%";
                        setHeight(h);
                        ProjectEventBus.sendLog('add', ' ')
                    }} icon={height === "100%" ? <CollapseIcon/> : <ExpandIcon/>}/>
                </Tooltip>
                <Button variant="plain" onClick={() => {
                    setShowLog(false, 'none');
                    setHeight(INITIAL_LOG_HEIGHT);
                    ProjectEventBus.sendLog('set', '')
                }} icon={<CloseIcon/>}/>
            </div>
        );
    }

    return (showLog ?
        <PageSection className="project-log" padding={{default: "noPadding"}} style={{height: height}}>
            {tab === 'log' && <ProjectLog autoScroll={autoScroll} isTextWrapped={isTextWrapped} header={getButtons()}/>}
            {tab === 'events' && currentPodName && <PodEventsLog currentPodName={currentPodName} header={getButtons()}/>}
        </PageSection>
        : <></>);
}
