export const complexParamTypeValues = [
    { value: 'array', label: 'array', disabled: false },
    { value: 'enum', label: 'enum', disabled: false },
];

export const simpleParamTypeValues = [
    { value: 'string', label: 'string', disabled: false },
    { value: 'integer', label: 'integer', disabled: false },
    { value: 'number', label: 'number', disabled: false },
    { value: 'boolean', label: 'boolean', disabled: false },
];

export const stringAsValues = [
    { value: 'string', label: 'string', disabled: false },
    { value: 'byte', label: 'byte', disabled: false },
    { value: 'binary', label: 'binary', disabled: false },
    { value: 'date', label: 'date', disabled: false },
    { value: 'datetime', label: 'datetime', disabled: false },
    { value: 'password', label: 'password', disabled: false },
];

export const integerAsValues = [
    { value: 'integer', label: 'integer', disabled: false },
    { value: 'int32', label: 'int32', disabled: false },
    { value: 'int64', label: 'int64', disabled: false }
];

export const numberAsValues = [
    { value: 'number', label: 'number', disabled: false },
    { value: 'float', label: 'float', disabled: false },
    { value: 'double', label: 'double', disabled: false }
];

export const paramTypeValues = [
    { value: 'please choose', label: 'Select type', disabled: true },
    ...simpleParamTypeValues,
    ...complexParamTypeValues,
];