/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";

function TalismanLogo(classNames?: string) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="337.659"
            height="340.759"
            preserveAspectRatio="xMidYMid"
            version="1.1"
            viewBox="0 0 337.659 340.759"
            className={classNames ? classNames : "logo"}
        >
            <defs>
                <circle id="path-1" cx="128" cy="128" r="128"></circle>
                <linearGradient
                    x1="-26.051"
                    x2="254.316"
                    y1="271.331"
                    y2="0.048"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0%" stopColor="#4790bb" stopOpacity="1"></stop>
                    <stop offset="10.996%" stopColor="#64b7db" stopOpacity="1"></stop>
                    <stop offset="94.502%" stopColor="#326ea0" stopOpacity="1"></stop>
                </linearGradient>
                <linearGradient
                    id="linearGradient-4"
                    x1="-32.163"
                    x2="259.338"
                    y1="277.029"
                    y2="-5.028"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0%" stopColor="#F69923"></stop>
                    <stop offset="8.048%" stopColor="#F79A23"></stop>
                    <stop offset="41.874%" stopColor="#E97826"></stop>
                </linearGradient>
                <linearGradient
                    x1="217.945"
                    x2="99.459"
                    y1="67.505"
                    y2="247.005"
                    gradientTransform="scale(.96442 1.0369)"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient-4"
                >
                    <stop offset="0%" stopColor="#92d6d5" stopOpacity="1"></stop>
                    <stop offset="41.191%" stopColor="#79b7cc" stopOpacity="1"></stop>
                    <stop offset="73.271%" stopColor="#5891c5" stopOpacity="1"></stop>
                </linearGradient>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <clipPath clipPathUnits="userSpaceOnUse">
                    <path
                        fill="#666"
                        fillOpacity="1"
                        stroke="none"
                        strokeDasharray="none"
                        strokeDashoffset="0"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="4"
                        strokeOpacity="1"
                        strokeWidth="2.117"
                        d="M1.776 291.57c-1.826.174-3.294.919-3.49 2.164-.263 1.667 1.567 3.341 4.089 3.739 1.217.191 2.434.053 3.38-.384.731.528 1.67.9 2.67 1.06 2.522.398 4.78-.631 5.042-2.299.263-1.667-1.49-1.943-4.257-2.179-2.766-.236-3.616-1.688-5.747-2.046a6.755 6.755 0 00-1.687-.055z"
                        opacity="1"
                        vectorEffect="none"
                    ></path>
                </clipPath>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <linearGradient
                    x1="-26.051"
                    x2="254.316"
                    y1="271.331"
                    y2="0.048"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0%" stopColor="#4790bb" stopOpacity="1"></stop>
                    <stop offset="10.996%" stopColor="#64b7db" stopOpacity="1"></stop>
                    <stop offset="94.502%" stopColor="#326ea0" stopOpacity="1"></stop>
                </linearGradient>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <linearGradient
                    x1="-26.051"
                    x2="254.316"
                    y1="271.331"
                    y2="0.048"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0%" stopColor="#4790bb" stopOpacity="1"></stop>
                    <stop offset="10.996%" stopColor="#64b7db" stopOpacity="1"></stop>
                    <stop offset="94.502%" stopColor="#326ea0" stopOpacity="1"></stop>
                </linearGradient>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <linearGradient
                    x1="-26.051"
                    x2="254.316"
                    y1="271.331"
                    y2="0.048"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0%" stopColor="#4790bb" stopOpacity="1"></stop>
                    <stop offset="10.996%" stopColor="#64b7db" stopOpacity="1"></stop>
                    <stop offset="94.502%" stopColor="#326ea0" stopOpacity="1"></stop>
                </linearGradient>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <linearGradient
                    x1="-26.051"
                    x2="254.316"
                    y1="271.331"
                    y2="0.048"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#2d4150" stopOpacity="1"></stop>
                    <stop offset="0.11" stopColor="#2d4150" stopOpacity="1"></stop>
                    <stop offset="0.945" stopColor="#2d4150" stopOpacity="1"></stop>
                </linearGradient>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <linearGradient
                    x1="-26.051"
                    x2="254.316"
                    y1="271.331"
                    y2="0.048"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0%" stopColor="#4790bb" stopOpacity="1"></stop>
                    <stop offset="10.996%" stopColor="#64b7db" stopOpacity="1"></stop>
                    <stop offset="94.502%" stopColor="#326ea0" stopOpacity="1"></stop>
                </linearGradient>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <clipPath clipPathUnits="userSpaceOnUse">
                    <path
                        fill="#666"
                        fillOpacity="1"
                        stroke="none"
                        strokeDasharray="none"
                        strokeDashoffset="0"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="4"
                        strokeOpacity="1"
                        strokeWidth="2.117"
                        d="M1.776 291.57c-1.826.174-3.294.919-3.49 2.164-.263 1.667 1.567 3.341 4.089 3.739 1.217.191 2.434.053 3.38-.384.731.528 1.67.9 2.67 1.06 2.522.398 4.78-.631 5.042-2.299.263-1.667-1.49-1.943-4.257-2.179-2.766-.236-3.616-1.688-5.747-2.046a6.755 6.755 0 00-1.687-.055z"
                        opacity="1"
                        vectorEffect="none"
                    ></path>
                </clipPath>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <clipPath clipPathUnits="userSpaceOnUse">
                    <path
                        fill="#666"
                        fillOpacity="1"
                        stroke="none"
                        strokeDasharray="none"
                        strokeDashoffset="0"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="4"
                        strokeOpacity="1"
                        strokeWidth="2.117"
                        d="M1.776 291.57c-1.826.174-3.294.919-3.49 2.164-.263 1.667 1.567 3.341 4.089 3.739 1.217.191 2.434.053 3.38-.384.731.528 1.67.9 2.67 1.06 2.522.398 4.78-.631 5.042-2.299.263-1.667-1.49-1.943-4.257-2.179-2.766-.236-3.616-1.688-5.747-2.046a6.755 6.755 0 00-1.687-.055z"
                        opacity="1"
                        vectorEffect="none"
                    ></path>
                </clipPath>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <clipPath clipPathUnits="userSpaceOnUse">
                    <path
                        fill="#666"
                        fillOpacity="1"
                        stroke="none"
                        strokeDasharray="none"
                        strokeDashoffset="0"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="4"
                        strokeOpacity="1"
                        strokeWidth="2.117"
                        d="M1.776 291.57c-1.826.174-3.294.919-3.49 2.164-.263 1.667 1.567 3.341 4.089 3.739 1.217.191 2.434.053 3.38-.384.731.528 1.67.9 2.67 1.06 2.522.398 4.78-.631 5.042-2.299.263-1.667-1.49-1.943-4.257-2.179-2.766-.236-3.616-1.688-5.747-2.046a6.755 6.755 0 00-1.687-.055z"
                        opacity="1"
                        vectorEffect="none"
                    ></path>
                </clipPath>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
                <clipPath clipPathUnits="userSpaceOnUse">
                    <path
                        fill="#666"
                        fillOpacity="1"
                        stroke="none"
                        strokeDasharray="none"
                        strokeDashoffset="0"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="4"
                        strokeOpacity="1"
                        strokeWidth="2.117"
                        d="M1.776 291.57c-1.826.174-3.294.919-3.49 2.164-.263 1.667 1.567 3.341 4.089 3.739 1.217.191 2.434.053 3.38-.384.731.528 1.67.9 2.67 1.06 2.522.398 4.78-.631 5.042-2.299.263-1.667-1.49-1.943-4.257-2.179-2.766-.236-3.616-1.688-5.747-2.046a6.755 6.755 0 00-1.687-.055z"
                        opacity="1"
                        vectorEffect="none"
                    ></path>
                </clipPath>
                <mask fill="#fff">
                    <use width="100%" height="100%" x="0" y="0" xlinkHref="#path-1"></use>
                </mask>
            </defs>
            <g fillOpacity="1" transform="translate(-281.071 -155.543)">
                <path
                    fill="#ec7a08"
                    strokeWidth="1.866"
                    d="M577.545 426.814c-2.937 0-5.905-.834-8.537-2.578-7.134-4.724-9.085-14.333-4.362-21.465 15.115-22.829 23.105-49.402 23.105-76.85 0-76.867-62.535-139.4-139.4-139.4-66.04 0-123.49 46.894-136.61 111.505-1.7 8.386-9.879 13.8-18.26 12.096-8.385-1.7-13.798-9.876-12.095-18.26 16.034-78.987 86.253-136.319 166.964-136.319 93.947 0 170.38 76.433 170.38 170.38 0 33.55-9.77 66.037-28.257 93.951-2.98 4.502-7.906 6.94-12.928 6.94z"
                ></path>
                <path
                    fill="#ec7a08"
                    strokeWidth="1.866"
                    d="M448.35 496.302c-80.71 0-150.93-57.332-166.966-136.321-1.702-8.386 3.712-16.56 12.096-18.26 8.38-1.709 16.56 3.716 18.26 12.096 13.12 64.611 70.57 111.507 136.61 111.507 27.446 0 54.02-7.99 76.85-23.104 7.126-4.722 16.741-2.773 21.465 4.361 4.723 7.132 2.772 16.742-4.362 21.465-27.916 18.486-60.403 28.256-93.953 28.256z"
                ></path>
                <path
                    fill="#06c"
                    stroke="none"
                    strokeDasharray="none"
                    strokeDashoffset="0"
                    strokeLinecap="round"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="12.131"
                    d="M541.036 326.109a92.5 92.5 0 01-92.5 92.5 92.5 92.5 0 01-92.5-92.5 92.5 92.5 0 0192.5-92.5 92.5 92.5 0 0192.5 92.5z"
                ></path>
                <path
                    fill="#ec7a08"
                    stroke="none"
                    strokeDasharray="none"
                    strokeDashoffset="0"
                    strokeLinecap="round"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="12.131"
                    d="M356.362 333.232a92.5 92.5 0 0092.192 85.395 92.5 92.5 0 0092.45-89.578c-.014.409-.028.818-.047 1.227a90.797 90.797 0 01-.283 4.137 95.902 95.902 0 01-.468 4.118 91.43 91.43 0 01-.91 5.453 92.789 92.789 0 01-.898 4.051 53.954 53.954 0 00-2.615-2.334 56.305 56.305 0 00-3.109-2.424 57.616 57.616 0 00-4.934-3.21 58.977 58.977 0 00-5.866-2.983 60.628 60.628 0 00-5.028-1.958 62.785 62.785 0 00-5.581-1.615 66.286 66.286 0 00-4.035-.84 68.813 68.813 0 00-5.402-.735 72.317 72.317 0 00-4.614-.305 77.416 77.416 0 00-3.633-.042 81.568 81.568 0 00-3.783.127 89.981 89.981 0 00-6.643.621 19.212 19.212 0 00-1.244.108 29.3 29.3 0 00-1.123.157 38.898 38.898 0 00-2.314.438 50.425 50.425 0 00-1.964.467 63.573 63.573 0 00-2.302.637 75.616 75.616 0 00-2.644.834 88.742 88.742 0 00-2.986 1.057 103.591 103.591 0 00-3.33 1.306 119.829 119.829 0 00-3.675 1.583 137.6 137.6 0 00-4.02 1.885 157.29 157.29 0 00-4.37 2.214 178.909 178.909 0 00-4.72 2.57 202.584 202.584 0 00-5.069 2.95 228.698 228.698 0 00-5.423 3.36c-.933.595-1.881 1.209-2.844 1.84a40.587 40.587 0 01-2.825 1.697 38.395 38.395 0 01-2.855 1.406 37.114 37.114 0 01-4.326 1.593 36.731 36.731 0 01-5.822 1.238 38.847 38.847 0 01-4.382.324 42.026 42.026 0 01-2.92-.046 44.981 44.981 0 01-2.913-.238 48.506 48.506 0 01-2.9-.417 53.565 53.565 0 01-2.88-.582 58.055 58.055 0 01-2.858-.733 64.467 64.467 0 01-2.83-.87 70.91 70.91 0 01-2.794-.992 77.418 77.418 0 01-2.756-1.101 86.316 86.316 0 01-4.048-1.825 100.086 100.086 0 01-3.93-1.991 116.574 116.574 0 01-2.546-1.396c-.838-.473-1.664-.952-2.48-1.435a148.84 148.84 0 01-2.408-1.46c-.79-.49-1.569-.98-2.333-1.47a193.129 193.129 0 01-4.414-2.917 406.904 406.904 0 01-4.046-2.792c-1.283-.898-2.497-1.756-3.636-2.555-.569-.4-1.119-.786-1.65-1.154-.53-.369-1.041-.72-1.531-1.052-.49-.333-.96-.646-1.408-.937-.446-.29-.871-.56-1.274-.805z"
                ></path>
            </g>
        </svg>
    )
}

export default TalismanLogo;
