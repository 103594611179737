import * as React from 'react';
import {shallow} from "zustand/shallow";
import {useDashboardStore, useMetricStore} from "../DashboardStore";
import {Metric} from "../DashboardModels";
import {getValueFromMetricByRoute} from "../DashboardFunc";

interface Props {
    routeId: string
}

export function MetricNodeAttachment(props: Props) {

    const [containerName] = useDashboardStore((s) => [s.containerName], shallow);
    const [metrics, updated] = useMetricStore((s) => [s.metrics, s.updated], shallow);

    const metric: Metric = metrics.filter(m => m.containerName === containerName).at(0) || new Metric();
    const routeId = props.routeId;

    const form = new Intl.NumberFormat('en-US');
    const failed = getValueFromMetricByRoute(routeId, metric.failedList);
    const inflight = getValueFromMetricByRoute(routeId, metric.inflightList);
    const total = getValueFromMetricByRoute(routeId, metric.totalList);

    return (<foreignObject className="node" x="-50" y="-10" width="160px" height="85px">
        <span className="pf-v5-c-badge metric metric-failed failed-color">{form.format(failed)}</span>
        <span className="pf-v5-c-badge metric metric-inflight inflight-color">{form.format(inflight)}</span>
        <div className="metric-panel-total">
            <span className="pf-v5-c-badge metric metric-total total-color">{form.format(total)}</span>
        </div>
    </foreignObject>)
}