
import axios, {AxiosResponse} from "axios";
import {KaravanApi} from "../../api/KaravanApi";
import {ErrorEventBus} from "../../api/ErrorEventBus";
import {CatalogProject} from "./CatalogModels";
import {Project} from "../../api/ProjectModels";

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
const instance = KaravanApi.getInstance();

export class CatalogApi {

    static accessUser?: any;

    static async getCatalog(after: (projects: CatalogProject[]) => void) {
        instance.get('/ui/catalog')
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                } else {
                    after([]);
                }
            }).catch(err => {
            ErrorEventBus.sendApiError(err);
            after([]);
        });
    }

    static async reloadCatalog(after: (result: boolean, res: {} | any) => void) {
        try {
            const params = {'userId': KaravanApi.getUserId()};
            instance.post('/ui/catalog/reload', params)
                .then(res => {
                    if (res.status === 200) {
                        after(true, res);
                    }
                }).catch(err => {
                after(false, err);
            });
        } catch (error: any) {
            after(false, error);
        }
    }

    static async createProject(catalogProjectId: string,
                               catalogName: string,
                               projectId: string,
                               projectName: string,
                               after: (result: boolean, res: AxiosResponse<Project> | any) => void) {
        try {
            const params = {
                userId : KaravanApi.getUserId(),
                catalogProjectId : catalogProjectId,
                catalogName : catalogName,
                projectId : projectId,
                projectName : projectName,
            };
            instance.post('/ui/catalog/create', params)
                .then(res => {
                    if (res.status === 200) {
                        after(true, res);
                    }
                }).catch(err => {
                after(false, err);
            });
        } catch (error: any) {
            after(false, error);
        }
    }
}
