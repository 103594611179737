import React from 'react';
import {
    Chart, ChartAxis,
    ChartGroup,
    ChartLine
} from "@patternfly/react-charts";
import {shallow} from "zustand/shallow";
import {useMetricStore} from "../DashboardStore";
import {CustomTheme2Red} from "./CustomTheme";
import {merge} from "../DashboardFunc";

export interface Props {
    containerName?: string
    hideLegend?: boolean
}

export function CardMessages(props: Props) {

    const [history] = useMetricStore((s) => [s.history], shallow)

    let inflight: number[] = []
    let failedDiffs: number[] = []
    let failedTotal: number[] = []

    const cName = props.containerName;
    history.filter(m => cName === undefined || m.containerName === cName).forEach(h => {
        const m = h.metrics;
        const i = m ? m.map(x => x.inflight) : [];
        inflight = merge(inflight, i);
        const f = m ? m.map(x => x.failedDiff) : [];
        failedDiffs = merge(failedDiffs, f);
        const ft = m ? m.map(x => x.failed) : [];
        failedTotal = merge(failedTotal, ft);
    })

    const lastInflight = (inflight.length > 1 ? inflight.at(inflight.length - 1) : 0) || 0;
    const lastFailed = (failedDiffs.length > 1 ? failedDiffs.at(failedDiffs.length - 1) : 0) || 0;
    const lastTotalFailed = (failedTotal.length > 1 ? failedTotal.at(failedTotal.length - 1) : 0) || 0;
    const legend = props.hideLegend !== true ? [{name: `Inflight: ${lastInflight}`}, {name: `Failed: ${lastTotalFailed}/${lastFailed}`}] : undefined;
    const padding = props.hideLegend !== true ?  {bottom: 50, left: 0, right: 0, top: 0} : {bottom: 10, left: 45, right: 10, top: 10};
    const dependentAxis = props.hideLegend !== true ?  {axis: {strokeWidth: 0}, tickLabels: {fontSize: 0}, ticks: {strokeWidth: 0}} : {axis: {strokeWidth: 1}, tickLabels: {fontSize: 8}, ticks: {strokeWidth: 1}};

    return (
        <Chart
            ariaDesc="Messages"
            ariaTitle="Messages"
            legendData={legend}
            legendPosition={"bottom"}
            minDomain={{y: 0}}
            width={250}
            height={150}
            padding={padding}
            theme={CustomTheme2Red()}
            showAxis={false}
        >
            <ChartAxis style={{axis: {strokeWidth: 0}, tickLabels: {fontSize: 0}, ticks: {strokeWidth: 0}}} />
            <ChartAxis dependentAxis style={dependentAxis}/>
            <ChartGroup style={{data: {strokeWidth: ({data}) => 1}}}>
                {inflight?.length > 0 && <ChartLine
                    data={inflight.map((v, i) => {
                        return {name: 'Inflight', x: i, y: v}
                    })}
                    style={{
                        data: {
                            strokeWidth: '2'
                        }
                    }}
                />}
                {failedTotal?.length > 0 && <ChartLine
                    data={failedTotal.map((v, i) => {
                        return {name: 'Failed Total', x: i, y: v}
                    })}
                    style={{
                        data: {
                            strokeWidth: '2'
                        }
                    }}
                />}
                {failedDiffs?.length > 0 && <ChartLine
                    data={failedDiffs.map((v, i) => {
                        return {name: 'Failed', x: i, y: v}
                    })}
                    style={{
                        data: {
                            strokeWidth: '2'
                        }
                    }}
                />}
            </ChartGroup>
        </Chart>
    )

}