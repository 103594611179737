import React, {useState} from 'react';
import {
    Bullseye,
    EmptyState, EmptyStateIcon, EmptyStateVariant,
    Spinner,
    EmptyStateHeader
} from '@patternfly/react-core';
import {
	TableVariant,
	Tbody,
	Td,
	Th,
	Thead,
	Tr
} from '@patternfly/react-table';
import {
	Table
} from '@patternfly/react-table/deprecated';
import SearchIcon from "@patternfly/react-icons/dist/esm/icons/search-icon";
import {shallow} from "zustand/shallow";
import {JwtUsersTableRow} from "./JwtUsersTableRow";
import {useJwtStore} from "./JwtStore";
import {JwtAccessUser} from "./JwtModels";

export function JwtUsersTable () {

    const [users, filter] = useJwtStore((s) => [s.users, s.filter], shallow);
    const [loading] = useState<boolean>(true);

    function getEmptyState() {
        return (
            <Tbody>
                <Tr>
                    <Td colSpan={8}>
                        <Bullseye>
                            {loading && <Spinner className="progress-stepper" diameter="80px" aria-label="Loading..."/>}
                            {!loading &&
                                <EmptyState variant={EmptyStateVariant.sm}>
                                    <EmptyStateHeader titleText="No results found" icon={<EmptyStateIcon icon={SearchIcon}/>} headingLevel="h2" />
                                </EmptyState>
                            }
                        </Bullseye>
                    </Td>
                </Tr>
            </Tbody>
        )
    }

    const conts = users.filter(user =>
        user.username?.toLowerCase().includes(filter)
        || user.firstName.toLowerCase().includes(filter)
        || user.lastName.toLowerCase().includes(filter)
    );
    return (
        <Table aria-label="Gateway Users" variant={TableVariant.compact}>
            <Thead>
                <Tr>
                    <Th screenReaderText="Row expansion" />
                    <Th width={15} key='username'>Username</Th>
                    <Th width={15} key='firstName'>First Name</Th>
                    <Th width={15} key='lastName'>Last Name</Th>
                    <Th width={15} key='email'>Email</Th>
                    <Th width={25} key='tokenId' textCenter>Token ID</Th>
                    <Th width={15} key='tokenExpire' textCenter>Expire Date</Th>
                    <Th key='scope' textCenter modifier='fitContent'>Scope</Th>
                </Tr>
            </Thead>
            {conts?.map((user: JwtAccessUser, index: number) => (
                <JwtUsersTableRow key={user.username} index={index} user={user}/>
            ))}
            {conts?.length === 0 && getEmptyState()}
        </Table>
    )

}