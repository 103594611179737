import {RegionsIcon} from '@patternfly/react-icons';

import {DefaultNode, observer} from '@patternfly/react-topology';
import './topology.css';
import {CamelUi} from "../../../designer/utils/CamelUi";
import {getDesignerIcon} from "../../../designer/icons/KaravanIcons";
import {MetricNodeAttachment} from "./MetricNodeAttachment";


function getIcon(data: any) {
    if (['route', 'rest'].includes(data.icon)) {
        return (
            <g transform={`translate(14, 14)`}>
                {getDesignerIcon(data.icon)}
            </g>
        )
    } else if (data.icon === 'element') {
        return (
            <g transform={`translate(14, 14)`}>
                {CamelUi.getConnectionIcon(data.step)}
            </g>
        )
    }
    return <RegionsIcon/>;
}

function getAttachments(data: any) {
    if (data?.step?.dslName === 'RouteDefinition') {
        const routeId = data?.step?.id;
        return (<MetricNodeAttachment routeId={routeId}/>)
    } else <></>
}

const MetricNode: React.FC<any> = observer(({element, ...rest}) => {

    const data = element.getData();
    const badge: string = data.badge?.substring(0, 1).toUpperCase();

    return (
        <DefaultNode
            badge={badge}
            showStatusDecorator
            className="common-node"
            scaleLabel={false}
            element={element}
            attachments={getAttachments(data)}
            {...rest}
        >
            {getIcon(data)}
        </DefaultNode>
    )
})
export default MetricNode;