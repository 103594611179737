import React from 'react';
import '../../../designer/karavan.css';
import "../dashboard.css"
import {Table, TableVariant, Td, Th, Thead, Tr} from "@patternfly/react-table";
import {getRoutesFromMetric, getValueFromMetricByRoute} from "../DashboardFunc";
import {Metric} from "../DashboardModels";

export interface Props {
    metric: Metric
}

export function RoutesTable(props: Props) {

    const metric = props.metric;
    const routes = getRoutesFromMetric(metric).sort();
    const form = new Intl.NumberFormat('en-US');

    return (
        <Table aria-label="Routes table" variant={TableVariant.compact}
               style={{borderTop: "1px solid var(--pf-v5-c-table--BorderColor)"}}>
            <Thead>
                <Tr>
                    <Th hasLeftBorder>Route Id</Th>
                    <Th modifier="fitContent">Total</Th>
                    <Th modifier="fitContent">Succeeded</Th>
                    <Th modifier="fitContent">Failed</Th>
                    <Th hasRightBorder modifier="fitContent">Inflight</Th>
                </Tr>
            </Thead>
            {[...new Set(routes)].map(route => (
                <Tr key={route}>
                    <Td hasLeftBorder>{route}</Td>
                    <Td style={{textAlign: "right"}} datatype={"number"}>{form.format(getValueFromMetricByRoute(route, metric.totalList))}</Td>
                    <Td style={{textAlign: "right"}} datatype={"number"}>{form.format(getValueFromMetricByRoute(route, metric.succeededList))}</Td>
                    <Td style={{textAlign: "right"}} datatype={"number"}>{form.format(getValueFromMetricByRoute(route, metric.failedList))}</Td>
                    <Td hasRightBorder style={{textAlign: "right"}} datatype={"number"}>{form.format(getValueFromMetricByRoute(route, metric.inflightList))}</Td>
                </Tr>
            ))}
        </Table>
    )

}