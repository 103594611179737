import React, {useEffect} from 'react';
import {
    Button,
    PageSection, Text,
    TextContent, Toolbar, ToolbarContent, ToolbarItem,
} from '@patternfly/react-core';
import {MainToolbar} from "../../designer/MainToolbar";
import {shallow} from "zustand/shallow";
import './jwt.css'
import {JwtUsersTable} from "./JwtUsersTable";
import {useJwtStore} from "./JwtStore";
import {ProjectService} from "../../api/ProjectService";
import {DashboardService} from "../dashboard/DashboardService";
import {JwtService} from "./JwtService";
import {JwtUserModal} from "./JwtUserModal";
import RefreshIcon from "@patternfly/react-icons/dist/esm/icons/sync-alt-icon";
import PlusIcon from "@patternfly/react-icons/dist/esm/icons/plus-icon";

interface Props {
    dark: boolean,
}

export const JwtPage = (props: Props) => {

    const [openModal, setOpenModal, setCurrentUser]
        = useJwtStore((s) => [s.openModal, s.setOpenModal, s.setCurrentUser], shallow)

    useEffect(() => {
        ProjectService.refreshProjects();
        ProjectService.refreshAllDeploymentStatuses();
        ProjectService.refreshAllContainerStatuses();
        DashboardService.refreshAllHealth();
        JwtService.refreshUsers();
    }, [openModal]);

    function title() {
        return (<TextContent>
            <Text component="h2">JWT Tokens</Text>
        </TextContent>);
    }

    function toolbar() {
        return (
            <Toolbar id="toolbar-group-types">
                <ToolbarContent>
                    <ToolbarItem>
                        <Button icon={<RefreshIcon/>}
                                variant={"link"}
                                onClick={e => JwtService.refreshUsers()}
                        />
                    </ToolbarItem>
                    <ToolbarItem>
                        <Button className="dev-action-button"
                                icon={<PlusIcon/>}
                                onClick={e => {
                                    setCurrentUser(undefined)
                                    setOpenModal(true)
                                }}
                        >Create</Button>
                    </ToolbarItem>
                </ToolbarContent>
            </Toolbar>
        );
    }

    return (
        <PageSection className="jwt-page" padding={{default: 'noPadding'}}>
            <PageSection className="tools-section" padding={{default: 'noPadding'}}>
                <MainToolbar title={title()} tools={toolbar()}/>
            </PageSection>
            <PageSection isFilled className="scrolled-section">
                <JwtUsersTable/>
                <JwtUserModal/>
            </PageSection>
        </PageSection>
    )
}