
import {Navigate, Route, Routes} from 'react-router-dom';
import React from "react";
import {ProjectsPage} from "../projects/ProjectsPage";
import {ProjectPage} from "../project/ProjectPage";
import {ServicesPage} from "../services/ServicesPage";
import {ContainersPage} from "../containers/ContainersPage";
import {ResourcesPage} from "../resources/ResourcesPage";
import {DashboardPage} from "../talisman/dashboard/DashboardPage";
import {ConfigurationPage} from "../config/ConfigurationPage";
import {NotAuthorizedPage} from "./NotAuthorizedPage";
import {KnowledgebaseWrapper} from "./KnowledgebaseWrapper";
import {JwtPage} from "../talisman/jwt/JwtPage";
import {SwaggerPage} from "../talisman/swagger/SwaggerPage";
import {AccessPage} from "../talisman/acl/AccessPage";
import {ProjectsToolbarWithCatalog} from "../talisman/projects/ProjectsToolbarWithCatalog";
import {ProjectsPageWithCatalog} from "../talisman/projects/ProjectsPageWithCatalog";

export function MainRoutes() {

    return (
        <Routes>
            <Route path="/dashboard" element={<DashboardPage key={'dashboard'}/>}/>
            <Route path="/projects" element={<ProjectsPageWithCatalog key={'projects'}/>}/>
            <Route path="/projects/:projectId" element={<ProjectPage key={'project'}/>}/>
            <Route path="/resources" element={<ResourcesPage key={'resources'}/>}/>
            <Route path="/services" element={<ServicesPage key="services"/>}/>
            <Route path="/containers" element={<ContainersPage key="services"/>}/>
            <Route path="/knowledgebase" element={<KnowledgebaseWrapper dark={false}/>}/>
            <Route path="/configuration" element={<ConfigurationPage dark={false}/>}/>
            <Route path="/acl" element={<AccessPage dark={false}/>}/>
            <Route path="/jwt" element={<JwtPage dark={false}/>}/>
            <Route path="/swagger/*" element={<SwaggerPage dark={false}/>}/>
            <Route path="/403" element={<NotAuthorizedPage/>}/>
            <Route path="*" element={<Navigate to="/dashboard" replace/>}/>
        </Routes>
    )
}
