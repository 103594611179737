import React, {useEffect} from 'react';
import {
    Alert,
    Button, capitalize, Card, CardBody, CardHeader, CardTitle,
    Form, FormAlert, FormGroup,
    Modal,
    ModalVariant, TextInput,
} from '@patternfly/react-core';
import {SubmitHandler, useForm} from "react-hook-form";
import {Project} from "../../api/ProjectModels";
import {useFormUtil} from "../../util/useFormUtil";
import {AxiosResponse} from "axios";
import {useCatalogStore} from "./CatalogStore";
import {isValidProjectId} from "../../util/StringUtils";
import {shallow} from "zustand/shallow";
import {CatalogApi} from "./CatalogApi";
import {EventBus} from "../../designer/utils/EventBus";
import {ProjectService} from "../../api/ProjectService";
import {useProjectsStore} from "../../api/ProjectStore";

export function CreateProjectFromCatalogModal() {

    const [showCreateProjectModal, setShowCreateProjectModal, selectedProject]
        = useCatalogStore((s) => [s.showCreateProjectModal, s.setShowCreateProjectModal, s.selectedProject], shallow)
    const [projects] = useProjectsStore((s) => [s.projects], shallow);
    const [isReset, setReset] = React.useState(false);
    const [backendError, setBackendError] = React.useState<string>();
    const formContext = useForm<Project>({mode: "all"});
    const {getTextField} = useFormUtil(formContext);
    const {
        formState: {errors},
        handleSubmit,
        reset,
        trigger
    } = formContext;

    useEffect(() => {
        reset(new Project({projectId: selectedProject?.projectId, name: selectedProject?.name}));
        setBackendError(undefined);
        setReset(true);
    }, [reset]);

    React.useEffect(() => {
        isReset && trigger();
    }, [trigger, isReset]);

    function closeModal() {
        setShowCreateProjectModal(false);
    }

    const onSubmit: SubmitHandler<Project> = (data) => {
        if (selectedProject) {
            const catalogProjectId = selectedProject.projectId;
            const catalogName = selectedProject.catalog;
            const projectId = data.projectId;
            const projectName = data.name;
            CatalogApi.createProject(catalogProjectId, catalogName, projectId, projectName, after);
        }
    }

    function after (result: boolean, res: AxiosResponse<Project> | any) {
        if (result) {
            onSuccess(res.projectId);
        } else {
            setBackendError(res?.response?.data);
        }
    }

    function onSuccess (projectId: string) {
        EventBus.sendAlert( "Success", "Project successfully created", "success");
        ProjectService.refreshProjectData(projectId);
        ProjectService.refreshProjects();
        setShowCreateProjectModal(false);
    }

    function onKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
        if (event.key === 'Enter') {
            handleSubmit(onSubmit)()
        }
    }

    return (
        <Modal
            title="Create new project from Catalog"
            variant={ModalVariant.small}
            isOpen={showCreateProjectModal}
            onClose={closeModal}
            onKeyDown={onKeyDown}
            actions={[
                <Button key="confirm" variant="primary"
                        onClick={handleSubmit(onSubmit)}
                        isDisabled={Object.getOwnPropertyNames(errors).length > 0}
                >
                    Save
                </Button>,
                <Button key="cancel" variant="secondary" onClick={closeModal}>Cancel</Button>
            ]}
            className="new-project"
        >
            <Form isHorizontal={true} autoComplete="off">
                <Card isCompact isFlat>
                    <CardBody>
                        <Form isHorizontal={true} autoComplete="off">
                        <FormGroup label="Catalog" fieldId={'catalogName'} isRequired>
                            <TextInput type="text" id='catalogProjectName' value={capitalize(selectedProject?.catalog || '')} readOnly isDisabled></TextInput>
                        </FormGroup>
                        <FormGroup label="Project ID" fieldId={'catalogProjectName'} isRequired>
                            <TextInput type="text" id='catalogProjectName' value={selectedProject?.name} readOnly isDisabled></TextInput>
                        </FormGroup>
                        <FormGroup label="Project Name" fieldId={'catalogProjectName'} isRequired>
                            <TextInput type="text" id='catalogProjectName' value={selectedProject?.name} readOnly isDisabled></TextInput>
                        </FormGroup>
                        </Form>
                    </CardBody>
                </Card>
                {getTextField('projectId', 'Project ID', {
                    regex: v => isValidProjectId(v) || 'Only lowercase characters, numbers and dashes allowed',
                    length: v => v.length > 5 || 'Project ID should be longer that 5 characters',
                    name: v => !['templates', 'kamelets', 'karavan', 'talisman'].includes(v) || "'templates', 'kamelets', 'karavan' , 'talisman' can't be used as project",
                    uniques: v => !projects.map(p=> p.name).includes(v) || "Project already exists!",
                })}
                {getTextField('name', 'Name', {
                    length: v => v.length > 5 || 'Project name should be longer that 5 characters',
                })}
                {backendError &&
                    <FormAlert>
                        <Alert variant="danger" title={backendError} aria-live="polite" isInline />
                    </FormAlert>
                }
            </Form>
        </Modal>
    )
}