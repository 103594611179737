import {createWithEqualityFn} from "zustand/traditional";
import {shallow} from "zustand/shallow";
import {JwtAccessUser} from "./JwtModels";

interface JwtState {
    openModal: boolean;
    setOpenModal: (openModal: boolean) => void;
    users: JwtAccessUser[];
    setUsers: (users: JwtAccessUser[]) => void;
    currentUser?: JwtAccessUser;
    setCurrentUser: (currentUser?: JwtAccessUser) => void;
    filter: string;
    setFilter: (filter: string) => void;

}

export const useJwtStore = createWithEqualityFn<JwtState>((set, get) => ({
    openModal: false,
    setOpenModal: (openModal: boolean)  => {
        set((state: JwtState) => {
            return {openModal: openModal};
        });
    },
    users: [],
    setUsers: (users: JwtAccessUser[])=> {
        set({users: users});
    },
    setCurrentUser: (currentUser?: JwtAccessUser) => {
        set({currentUser: currentUser});
    },
    filter: '',
    setFilter: (filter: string)=> {
        set({filter: filter?.toLowerCase()});
    },
}), shallow)

