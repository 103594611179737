import React, {useEffect, useState} from 'react';
import {shallow} from "zustand/shallow";
import {
    Flex, FlexItem,
    TreeView,
    TreeViewDataItem
} from "@patternfly/react-core";
import {useDocumentEditorStore} from "./DocumentStore";
import {Info, License, OpenApi31Schema, OpenApiPathItem, OpenApiPaths, Tag} from "@apicurio/data-models";
import {AddElementButton, DeleteElementButton, EditElementButton} from "./DocumentButtons";
import DataTypesIcon from "@patternfly/react-icons/dist/esm/icons/code-icon";
import ReplyIcon from "@patternfly/react-icons/dist/esm/icons/reply-all-icon";
import PathsIcon from "@patternfly/react-icons/dist/esm/icons/service-icon";
import TagsIcon from "@patternfly/react-icons/dist/esm/icons/tags-icon";
import {ModalDeleteConfirmation} from "./ModalDeleteConfirmation";
import {ModalDataType} from "./ModalDataType";
import {ModalPath} from "./ModalPath";
import {codeToOpenApiDocument, openApiDocumentToCode} from "./OpenApiUtils";
import './OpenApiTree.css'
import {useTreeUtil} from "./useTreeUtil";
import InfoIcon from "@patternfly/react-icons/dist/esm/icons/info-icon";
import DescriptionIcon from "@patternfly/react-icons/dist/esm/icons/info-alt-icon";
import LicenseIcon from "@patternfly/react-icons/dist/esm/icons/file-contract-icon";
import {ModalInformation} from "./ModalInformation";
import MarkdownPreview from "@uiw/react-markdown-preview";

export function OpenApiTree() {

    const [selectedType, setSelectedType, selectedName, setSelectedName, activeItem, setActiveItem, code, setCode]
        = useDocumentEditorStore((s) =>
        [s.selectedType, s.setSelectedType, s.selectedName, s.setSelectedName, s.activeItem, s.setActiveItem, s.code, s.setCode], shallow);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const {getTagRow, getPathRow, getDataTypeRow} = useTreeUtil();

    const onSelect = (_event: React.MouseEvent, treeViewItem: TreeViewDataItem) => {
        setActiveItem(treeViewItem);
    };

    useEffect(() => {
        if (activeItem === undefined) {
            // setActiveItem(getInfo(document.getInfo()))
        }
    }, []);

    const options = React.useMemo(() => {
        const result: TreeViewDataItem[] = [];
        try {
            const document = codeToOpenApiDocument(code);
            if (document) {
                result.push(getInfo(document.getInfo()) || [])
                result.push(getPaths(document.getPaths()))
                result.push(getDataTypes(document.getComponents()?.getSchemas()))
                result.push(getResponses(document.getComponents()?.getResponses()))
                result.push(getTags(document.getTags()) || [])
            }
        } catch (error: any) {
            console.error(error);
        }
        return result;
    }, [code]);

    function confirmDeletion() {
        const document = codeToOpenApiDocument(code);
        if (document) {
            if (selectedType === 'DataType' && selectedName) {
                document.getComponents().removeSchema(selectedName);
            } else if (selectedType === 'Path') {
                document.getPaths().removeItem(selectedName as string);
            } else if (selectedType === 'Response') {
                document.getComponents().removeResponse(selectedName as string);
            }
            setCode(openApiDocumentToCode(document));
        }
    }

    function addSchema(name: string, description?: string, example?: string) {
        const document = codeToOpenApiDocument(code);
        if (document) {
            const newSchema: OpenApi31Schema = document.getComponents().createSchema() as OpenApi31Schema;
            newSchema.setTitle(name);
            if (description) newSchema.setDescription(name);
            if (example) newSchema.setExample(example);
            document.getComponents()?.addSchema(name, newSchema);
            setCode(openApiDocumentToCode(document));
        }
    }

    function addResponse(name: string, description?: string) {
        const document = codeToOpenApiDocument(code);
        if (document) {
            const response = document.getComponents().createResponse();
            if (description) response.setDescription(name);
            document.getComponents()?.addResponse(name, response);
            setCode(openApiDocumentToCode(document));
        }
    }

    function addPath(name: string, pathItem?: OpenApiPathItem) {
        const document = codeToOpenApiDocument(code);
        if (document) {
            const pathItemToAdd = pathItem ? pathItem : document.getPaths().createPathItem();
            document.getPaths().addItem(name, pathItemToAdd)
            setCode(openApiDocumentToCode(document));
        }
    }

    function addInfo(info: Info) {
        const document = codeToOpenApiDocument(code);
        if (document) {
            const infoToAdd = info ? info : document.createInfo();
            document.setInfo(infoToAdd);
            setCode(openApiDocumentToCode(document));
        }
    }

    function getLicense(license: License): TreeViewDataItem {
        return {
            icon: <LicenseIcon/>,
            id: 'License',
            name: (
                <FlexItem>
                    <Flex>
                        <FlexItem>{license.getName()}</FlexItem>
                        <FlexItem>{license.getUrl()}</FlexItem>
                    </Flex>
                </FlexItem>
            )
        }
    }

    function getDescription(description: string): TreeViewDataItem {
        return {
            icon: <DescriptionIcon/>,
            id: 'Description',
            name: (
                <MarkdownPreview source={description} />
            )
        }
    }

    function getInfo(info: Info): TreeViewDataItem {
        return {
            icon: <InfoIcon/>,
            id: 'Information',
            hasBadge: false,
            name: (
                <div>{info.getTitle()} {info.getVersion()}</div>
            ),
            children: [getDescription(info.getDescription()), getLicense(info.getLicense())],
            action: (
                <EditElementButton tooltip='Edit Information'
                                   onClick={event => {
                                       setSelectedType('Info');
                                       setShowEditModal(true);
                                   }}
                />
            )
        }
    }

    function getPath(name: string, item: OpenApiPathItem): TreeViewDataItem {
        return {
            id: 'Path:' + name,
            hasBadge: false,
            name: getPathRow(name, item),
            action: (
                <Flex>
                    <EditElementButton tooltip='Edit path'
                                       onClick={event => {
                                           setSelectedType('Path');
                                           setSelectedName(name);
                                           setShowEditModal(true);
                                       }}
                    />
                    <DeleteElementButton tooltip='Delete path'
                                         onClick={event => {
                                             setSelectedType('Path');
                                             setSelectedName(name);
                                             setShowDeleteModal(true);
                                         }}
                    />
                </Flex>
            )
        }
    }

    function getPaths(paths: OpenApiPaths): TreeViewDataItem {
        return {
            icon: <PathsIcon/>,
            id: 'Paths',
            name: 'Paths',
            defaultExpanded: false,
            children: paths?.getItemNames().map(name => getPath(name, paths.getItem(name))),
            action: (
                <AddElementButton tooltip='Add path'
                                  onClick={event => {
                                      setSelectedType('Path');
                                      setSelectedName('');
                                      setShowEditModal(true);
                                  }}
                />
            )
        }
    }

    function getTag(tag: Tag): TreeViewDataItem {
        return {
            id: 'Tag:' + tag.getName(),
            name: getTagRow(tag),
            defaultExpanded: false,
            action: (
                <DeleteElementButton tooltip='Delete path'
                                     onClick={event => {
                                         setSelectedType('Tag');
                                         setSelectedName(tag.getName());
                                         setShowDeleteModal(true);
                                     }}
                />
            )
        }
    }

    function getTags(tags: Array<Tag>): TreeViewDataItem {
        return {
            icon: <TagsIcon/>,
            id: 'Tags',
            name: 'Tags',
            children: tags.map(tag => getTag(tag)),
            action: (
                <AddElementButton tooltip='Add path'
                                  onClick={event => {
                                      setSelectedType('Path');
                                      setSelectedName('');
                                      setShowEditModal(true);
                                  }}
                />
            )
        }
    }

    function getDataType(name: string, schema: OpenApi31Schema): TreeViewDataItem {
        return {
            id: 'DataType:' + name,
            name: getDataTypeRow(name, schema),
            action: (
                <DeleteElementButton tooltip='Delete data type'
                                     onClick={event => {
                                         setSelectedType('DataType');
                                         setSelectedName(name);
                                         setShowDeleteModal(true);
                                     }}
                />
            )
        }
    }

    function getDataTypes(schemas: any): TreeViewDataItem {
        const schemasItems: TreeViewDataItem[] = [];
        if (schemas) {
            Object.getOwnPropertyNames(schemas)
                .forEach((s, i) => {
                    schemasItems.push(getDataType(s, schemas[s]));
                })
        }
        return {
            icon: <DataTypesIcon/>,
            id: 'DataTypes',
            name: 'Data Types',
            defaultExpanded: false,
            children: schemasItems,
            action: (
                <AddElementButton tooltip='Add data type'
                                  onClick={event => {
                                      setSelectedType('DataType');
                                      setSelectedName('');
                                      setShowEditModal(true);
                                  }}
                />
            )
        }
    }

    function getResponses(schemas: any): TreeViewDataItem {
        const schemasItems: TreeViewDataItem[] = [];
        return {
            icon: <ReplyIcon/>,
            id: 'Responses',
            name: 'Responses',
            defaultExpanded: false,
            children: [],
            action: (
                <AddElementButton tooltip='Add response'
                                  onClick={event => {
                                      setSelectedType('Response');
                                      setSelectedName('');
                                      setShowEditModal(true);
                                  }}
                />
            )
        }
    }

    function closeModal() {
        setShowDeleteModal(false)
        setSelectedName(undefined)
        setShowEditModal(false)
    }

    return (
        <>
            <TreeView className='open-api-tree'
                      hasSelectableNodes
                      data={options}
                      hasGuides={true}
                      activeItems={activeItem ? [activeItem] : []}
                      onSelect={onSelect}
                      hasBadges
            />
            {showDeleteModal &&
                <ModalDeleteConfirmation
                    isOpen={showDeleteModal}
                    message={"Confirm deletion of " + selectedName + " ?"}
                    onConfirm={() => {
                        confirmDeletion();
                        closeModal()
                    }}
                    onCancel={() => closeModal()}
                />
            }
            {showEditModal && selectedType === 'Info' &&
                <ModalInformation
                    isOpen={showEditModal}
                    onConfirm={(info) => {
                        addInfo(info);
                        closeModal()
                    }}
                    onCancel={() => closeModal()}
                />
            }
            {showEditModal && selectedType === 'Path' &&
                <ModalPath
                    name={selectedName}
                    isOpen={showEditModal}
                    onConfirm={(name, path) => {
                        addPath(name, path)
                        closeModal()
                    }}
                    onCancel={() => closeModal()}
                />
            }
            {showEditModal && selectedType === 'DataType' &&
                <ModalDataType
                    isOpen={showEditModal}
                    onConfirm={(name, description, example) => {
                        addSchema(name, description, example)
                        closeModal()
                    }}
                    onCancel={() => closeModal()}
                />
            }
        </>
    )
}
