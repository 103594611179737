import React from 'react';
import {
    Flex, FlexItem,
    HelperText,
    HelperTextItem
} from "@patternfly/react-core";
import {OpenApi31Schema, OpenApiOperation, OpenApiPathItem, Tag} from "@apicurio/data-models";
import {splitByBraces} from "../util/StringUtils";

export function useTreeUtil() {

    function getTagRow(tag: Tag): React.ReactNode {
        return (
            <Flex className='tag-row'>
                <FlexItem>{tag.getName()}</FlexItem>
                <FlexItem>
                    <HelperText>
                        <HelperTextItem>{tag.getDescription()}</HelperTextItem>
                    </HelperText>
                </FlexItem>
            </Flex>
        )
    }

    function getPathRow(name: string, item: OpenApiPathItem): React.ReactNode {
        return (
            <Flex width='100%' className='path-row' gap={{default: "gap"}}>
                <FlexItem>
                    <Flex gap={{default: "gapSm"}}>
                        {getOperations(name, item)}
                    </Flex>
                </FlexItem>
                <FlexItem>
                    <Flex gap={{default: "gapNone"}}>
                        {splitByBraces(name).map(value => <div className={value.startsWith("{") ? 'path-row-path-param' : 'path-row-path'}>{value}</div>)}
                    </Flex>
                </FlexItem>
                {/*<FlexItem className='path-row-path'>{name}</FlexItem>*/}
            </Flex>
        )
    }

    function getOperations(pathName: string, item: OpenApiPathItem): React.ReactNode [] {
        const result: React.ReactNode[] = [];
        result.push(getOperationRow(pathName,'GET', item.getGet()))
        result.push(getOperationRow(pathName, 'POST', item.getPost()))
        result.push(getOperationRow(pathName, 'PUT', item.getPut()))
        result.push(getOperationRow(pathName, 'DELETE', item.getDelete()))
        result.push(getOperationRow(pathName, 'HEAD', item.getHead()))
        result.push(getOperationRow(pathName, 'OPTIONS', item.getOptions()))
        result.push(getOperationRow(pathName, 'PATCH', item.getPatch()))
        return result;
    }

    function getOperationRow(pathName: string, name: string, oper: OpenApiOperation): React.ReactNode {
        const classname = 'oper-name ' + (oper ? 'oper-'+name.toLowerCase() : 'no-oper')
        return (
            <Flex width='100%' className='oper-row' gap={{default: "gapNone"}}>
                <FlexItem className={classname}>{name}</FlexItem>
            </Flex>
        )
    }

    function getDataTypeRow(name: string, schema: OpenApi31Schema): React.ReactNode {
        // console.log(schema)
        return (
            <Flex className='tag-row'>
                <FlexItem>{name}</FlexItem>
                <FlexItem>
                    <HelperText>
                        <HelperTextItem>{schema.getType() ? schema.getType().toString() : ''}</HelperTextItem>
                    </HelperText>
                </FlexItem>
            </Flex>
        )
    }


    return {getTagRow, getPathRow, getDataTypeRow, getOperationRow}
}

