import React, {useEffect, useState} from 'react';
import '../editor/DataEditor.css';
import {useFileStore} from "../api/ProjectStore";
import {shallow} from "zustand/shallow";
import {Tab, Tabs, TabTitleIcon, TabTitleText} from "@patternfly/react-core";
import {getDesignerIcon} from "../designer/icons/KaravanIcons";
import {ModellerIcon} from "../talisman/TalismanUI";
import {OpenApiDesigner} from "./OpenApiDesigner";
import {CodeEditor} from "../editor/CodeEditor";
import {useDocumentEditorStore} from "./DocumentStore";

export function OpenApiEditor() {

    const [setCode, setFileName] = useDocumentEditorStore((s) =>
        [s.setCode, s.setFileName], shallow);
    const [file] = useFileStore((s) => [s.file], shallow)
    const [tab, setTab] = useState<string | number>('design');

    useEffect(() => {
        if (tab === 'design' && file) {
            setCode(file?.code)
            setFileName(file?.name)
        }
    }, [tab]);

    function getTabs() {
        return (
            <Tabs className="main-tabs" activeKey={tab} onSelect={(event, tabIndex) => setTab(tabIndex)}>
                <Tab eventKey="design"
                     title={
                         <div className="top-menu-item">
                             <TabTitleIcon>{<ModellerIcon/>}</TabTitleIcon>
                             <TabTitleText>Design</TabTitleText>
                         </div>
                     }
                />
                <Tab eventKey="code"
                     title={
                        <div className="top-menu-item">
                            <TabTitleIcon>{getDesignerIcon('code')}</TabTitleIcon>
                            <TabTitleText>Code</TabTitleText>
                        </div>
                    }
                />
            </Tabs>
        )
    }

    const isCode = tab === 'code' && file !== undefined;
    const isDesigner = tab === 'design' && file !== undefined;
    return (
        <div className='data-editor'>
            {getTabs()}
            {isDesigner && <OpenApiDesigner/>}
            {isCode && <CodeEditor/>}
        </div>
    )
}
