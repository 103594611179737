export class AccessUser {
    username: string = '';
    firstName: string = '';
    lastName: string = ''
    password: string = ''
    password2: string = ''
    email: string = '';
    passNeedUpdate: boolean = false;
    admin: boolean = false;
    developer: boolean = false;

    public constructor(init?: Partial<AccessUser>) {
        Object.assign(this, init);
    }
}

export class AccessRole {
    role: string = '';
    name: string = '';

    constructor(role: string, name: string) {
        this.role = role;
        this.name = name;
    }
}

export const TALISMAN_ADMIN = 'talisman-admin'
export const TALISMAN_DEVELOPER = 'talisman-developer'
