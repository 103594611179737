import React, {useEffect, useState} from 'react';
import "./ReadmePanel.css"
import MarkdownPreview from "@uiw/react-markdown-preview";
import {KaravanApi} from "../../../api/KaravanApi";
import {useAppConfigStore} from "../../../api/ProjectStore";
import {shallow} from "zustand/shallow";

const filename = 'dashboard.md';

export interface Props {
    projectId: string
}

export function ReadmePanel(props: Props) {

    const [config] = useAppConfigStore((state) => [state.config], shallow)
    const [markdown, setMarkdown] = useState<string>();

    useEffect(() => {
        setMarkdown(undefined)
        KaravanApi.getFiles(props.projectId, files => {
            const envFilename = config.environment + "." + filename;
            const envFile = files.filter(f => f.name === envFilename) || [];
            if (envFile && envFile.length > 0) {
                setMarkdown(envFile.at(0)?.code)
            } else {
                const file = files.filter(f => f.name === filename) || [];
                if (file && file.length > 0) {
                    setMarkdown(file.at(0)?.code)
                }
            }
        })
    }, [props.projectId]);

    return (
        <div style={{fontSize: "14px", fontFamily: "RedHat Text", padding: "12px"}} >
            {markdown && <MarkdownPreview className={"karavan"} source={markdown}/>}
        </div>
    )

}