import {createWithEqualityFn} from "zustand/traditional";
import {shallow} from "zustand/shallow";
import {OpenApi31Document, OpenApi31DocumentImpl} from "@apicurio/data-models";
import {TreeViewDataItem} from "@patternfly/react-core";

export type DocumentElementType = 'DataType' | 'Path' | 'Response' | 'Tag' | 'Operation' | 'Info';

interface DocumentEditorState {
    code?: string;
    setCode: (code?: string) => void;
    document: OpenApi31Document;
    setDocument: (document: OpenApi31Document, save?: boolean) => void;
    fileName?: string;
    setFileName: (fileName: string) => void;
    updateKey: number;
    saveKey: number;
    selectedType: DocumentElementType;
    setSelectedType: (selectedType: DocumentElementType) => void;
    selectedName?: string;
    setSelectedName: (selectedName?: string) => void;
    activeItem?: TreeViewDataItem;
    setActiveItem: (activeItem: TreeViewDataItem) => void;
}

export const useDocumentEditorStore = createWithEqualityFn<DocumentEditorState>((set) => ({
    setCode: (code?: string) => {
        set((state: DocumentEditorState) => {
            return {code: code};
        });
    },
    document: new OpenApi31DocumentImpl(),
    setDocument: (document: OpenApi31Document, save?:boolean) => {
        set((state: DocumentEditorState) => {
            if (save) {
                return {document: document, updateKey: state.updateKey + 1, saveKey: state.saveKey + 1};
            } else {
                return {document: document, updateKey: state.updateKey + 1};
            }
        });
    },
    setFileName: (fileName: string) => {
        set((state: DocumentEditorState) => {
            return {fileName: fileName};
        });
    },
    updateKey: 0,
    saveKey: 0,
    selectedType: 'Path',
    setSelectedType: (selectedType: DocumentElementType) => {
        set((state: DocumentEditorState) => {
            return {selectedType: selectedType};
        });
    },
    setSelectedName: (selectedName?: string) => {
        set((state: DocumentEditorState) => {
            return {selectedName: selectedName};
        });
    },
    setActiveItem: (activeItem: TreeViewDataItem) => {
        set((state: DocumentEditorState) => {
            return {activeItem: activeItem};
        });
    },
}), shallow)
