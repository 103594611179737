import {createWithEqualityFn} from "zustand/traditional";
import {shallow} from "zustand/shallow";
import {Health, Metric, MetricHistory} from "./DashboardModels";

interface DashboardState {
    showTopology: boolean;
    setShowTopology: (showTopology: boolean) => void;
    projectId?: string;
    setProjectId: (projectId: string) => void;
    containerName?: string;
    setContainerName: (containerName: string) => void;
}

export const useDashboardStore = createWithEqualityFn<DashboardState>((set) => ({
    showTopology: false,
    setShowTopology: (showTopology: boolean)  => {
        set((state: DashboardState) => {
            return {showTopology: showTopology};
        });
    },
    projectId: undefined,
    setProjectId: (projectId: string)  => {
        set((state: DashboardState) => {
            return {projectId: projectId};
        });
    },
    containerName: undefined,
    setContainerName: (containerName: string)  => {
        set((state: DashboardState) => {
            return {containerName: containerName};
        });
    },
}), shallow)

interface MetricState {
    metrics: Metric[];
    history: MetricHistory[];
    setMetrics: (metrics: Metric[]) => void;
    setMetricsHistory: (history: MetricHistory[]) => void;
    updated: number;
}

export const useMetricStore = createWithEqualityFn<MetricState>((set) => ({
    metrics: [],
    history: [],
    updated: 0,
    setMetrics: (metrics: Metric[])  => {
        set((state: MetricState) => {
            state.metrics.length = 0;
            state.metrics.push(...metrics);
            return {metrics: state.metrics, updated: Math.random()};
        });
    },
    setMetricsHistory: (history: MetricHistory[])  => {
        set((state: MetricState) => {
            state.history.length = 0;
            state.history.push(...history);
            return {history: state.history};
        });
    },
}), shallow)

interface HealthState {
    healths: Health[];
    setHealths: (healths: Health[]) => void;
}

export const useHealthStore = createWithEqualityFn<HealthState>((set) => ({
    healths: [],
    setHealths: (healths: Health[])  => {
        set((state: HealthState) => {
            state.healths.length = 0;
            state.healths.push(...healths);
            return {healths: state.healths};
        });
    },
}), shallow)

interface DockerInfoState {
    Architecture: string;
    ServerVersion: string;
    MemTotal: number;
    NCPU: number;
    Containers: number;
    ContainersPaused: number;
    ContainersRunning: number;
    ContainersStopped: number;
    setDockerInfo: (Architecture: string,
                    ServerVersion: string,
                    MemTotal: number,
                    NCPU: number,
                    Containers: number,
                    ContainersPaused: number,
                    ContainersRunning: number,
                    ContainersStopped: number) => void;
}

export const useDockerInfoStore = createWithEqualityFn<DockerInfoState>((set) => ({
    Architecture: '',
    ServerVersion: '',
    MemTotal: 0,
    NCPU: 0,
    Containers: 0,
    ContainersPaused: 0,
    ContainersRunning: 0,
    ContainersStopped: 0,
    setDockerInfo: (Architecture: string,
                    ServerVersion: string,
                    MemTotal: number,
                    NCPU: number,
                    Containers: number,
                    ContainersPaused: number,
                    ContainersRunning: number,
                    ContainersStopped: number)  => {
        set({
            Architecture: Architecture,
            ServerVersion: ServerVersion,
            MemTotal: MemTotal,
            NCPU: NCPU,
            Containers: Containers,
            ContainersPaused: ContainersPaused,
            ContainersRunning: ContainersRunning,
            ContainersStopped: ContainersStopped
        })
    },
}), shallow)

