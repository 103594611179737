import React, {useEffect, useState} from 'react';
import {
    Button,
    capitalize,
    Checkbox,
    Flex,
    FlexItem,
    Form,
    FormSelect,
    FormSelectOption,
    Modal,
    TreeView,
    TreeViewDataItem
} from "@patternfly/react-core";
import './SchemaEditor.css'
import {OpenApi31PathItem, OpenApi31Schema, OpenApiParameter, OpenApiPathItem} from "@apicurio/data-models";
import {useFileStore} from "../api/ProjectStore";
import {shallow} from "zustand/shallow";
import {SubmitHandler, useForm} from "react-hook-form";
import {useFormUtil} from "../util/useFormUtil";
import {codeToOpenApiDocument} from "./OpenApiUtils";
import {EventBus} from "../designer/utils/EventBus";
import {EditElementButton} from "./DocumentButtons";
import {getPathParams} from "../util/StringUtils";
import {
    OpenApi31Parameter
} from "@apicurio/data-models/src/io/apicurio/datamodels/models/openapi/v31/OpenApi31Parameter";
import {paramTypeValues, stringAsValues} from "./OpenApiTypes";
import {Schema} from "@apicurio/data-models/src/io/apicurio/datamodels/models/Schema";
import {
    StringStringListUnion
} from "@apicurio/data-models/src/io/apicurio/datamodels/models/union/StringStringListUnion";
import {string} from "yup";

interface Props {
    name?: string
    isOpen: boolean
    onConfirm: (name: string, path: OpenApiPathItem) => void
    onCancel: () => void
}

export function ModalPath(props: Props) {

    const [file] = useFileStore((s) => [s.file], shallow);
    const [pathItem, setPathItem] = useState<OpenApi31PathItem>();
    const [reloadTree, setReloadTree] = useState<string>();
    const [isReset, setReset] = React.useState(false);
    const formContext = useForm({defaultValues: {name: props.name}, mode: "all"});
    const {getTextField, getTextArea} = useFormUtil(formContext);
    const {
        formState: {errors},
        handleSubmit,
        reset,
        trigger,
        getValues
    } = formContext;

    useEffect(() => {
        try {
            reset({name: props.name});
            setReset(true);
            setPathItem(getPathItemFromDocument());
        } catch (err: any) {
            EventBus.sendAlert("Error", err?.message, "danger")
        }
    }, [reset]);

    React.useEffect(() => {
        isReset && trigger();
    }, [trigger, isReset]);


    const onSubmit: SubmitHandler<any> = (data) => {
        if (data.name && pathItem) {
            console.log(data.name, pathItem);
            // onConfirm(data.name, item)
        }
    }

    function getParameter(type: string, name: string, param?: OpenApi31Parameter): TreeViewDataItem {
        const disabled = param === undefined;
        const paramSchema: OpenApi31Schema | undefined = param?.getSchema() as OpenApi31Schema;
        const paramType = paramSchema?.getType();
        console.log(param, paramType)
        return {
            id: 'Parameter:' + type + ':' + name,
            name: (
                <Flex>
                    <FlexItem>{name}</FlexItem>
                    <FlexItem>
                        <Checkbox isDisabled={disabled} label="Required" id="required" isChecked={param?.isRequired()}
                                  onChange={(event, checked) => {
                                      if (param) {
                                          param?.setRequired(!param?.isRequired());
                                          setPathParam(param);
                                      }
                                  }}/>
                    </FlexItem>
                    <FlexItem>
                        <FormSelect isDisabled={disabled} value={paramType} aria-label="type" ouiaId="type"
                                    onChange={(_,value) => {
                                        if (param && paramSchema) {
                                            (param?.getSchema() as any).type = value;
                                            setPathParam(param);
                                        }
                                    }}>
                            {paramTypeValues.map((paramTypeValue, index) => (
                                <FormSelectOption key={index} value={paramTypeValue.value} label={paramTypeValue.label}/>
                            ))}
                        </FormSelect>
                    </FlexItem>
                    <FlexItem>
                        <div>{paramType?.asString() === 'array' ? 'of' : paramType?.asString() === 'boolean' ? '' : 'as'}</div>
                    </FlexItem>
                    <FlexItem>
                        <FormSelect isDisabled={disabled} value={'formSelectValue'} onChange={event => {
                        }} aria-label="format" ouiaId="format">
                            {stringAsValues.map((stringAsValue, index) => (
                                <FormSelectOption key={index} value={stringAsValue.value} label={stringAsValue.label}/>
                            ))}
                        </FormSelect>
                    </FlexItem>
                </Flex>
            ),
            action: (
                <EditElementButton tooltip='Edit Information'
                                   onClick={event => {
                                       if (type === 'path' && param === undefined) {
                                           addPathParam(name);
                                       }
                                   }}
                />
            )
        }
    }

    function addPathParam(name: string) {
        if (pathItem) {
            const param = pathItem.createParameter();
            param.setName(name)
            param.setIn('path')
            param.setRequired(false);
            param.setSchema(param.createSchema());
            setPathParam(param)
        }
    }

    function setPathParam(param: OpenApiParameter) {
        if (pathItem) {
            pathItem.removeParameter(param);
            pathItem.addParameter(param);
            setPathItem(pathItem);
            setReloadTree(Math.random().toString());
        }
    }

    function getParameterType(type: string, params: Array<OpenApi31Parameter>): TreeViewDataItem {
        const name = getValues('name');
        const parameters: TreeViewDataItem[] = [];
        if (pathItem && name && type === 'path') {
            const pathParams = getPathParams(name);
            pathParams.forEach(pathParam => {
                const param = params.filter(p => p.getIn() === type && p.getName() === pathParam).at(0);
                if (param) {
                    parameters.push(getParameter(type, param.getName(), param))
                } else {
                    parameters.push(getParameter(type, pathParam))
                }
            })
        } else {
            parameters.push(...params.filter(p => p.getIn() === type).map(p => getParameter(type, p.getName(), p)))
        }
        return {
            id: 'ParameterType:' + type,
            name: capitalize(type) + ' Parameters',
            children: parameters
        }
    }

    function getPathItemFromDocument(): OpenApi31PathItem | undefined {
        const document = codeToOpenApiDocument(file?.code);
        console.log(document)
        const name = getValues('name');
        if (name) {
            return document?.getPaths().getItem(name) as OpenApi31PathItem;
        } else {
            return document?.getPaths().createPathItem() as OpenApi31PathItem;
        }
    }

    const options = React.useMemo(() => {
        const result: TreeViewDataItem[] = [];
        try {
            const params: Array<OpenApi31Parameter> = [];
            const itemParams = pathItem?.getParameters() as OpenApi31Parameter[];
            if (itemParams) {
                params.push(...itemParams);
            }
            result.push(getParameterType('path', params))
            result.push(getParameterType('query', params))
            result.push(getParameterType('header', params))

        } catch (error: any) {
            console.error(error);
        }
        return result;
    }, [props.name, pathItem, reloadTree]);

    const {isOpen, onConfirm, onCancel} = props;
    return (
        <Modal
            className="modal-confirm"
            title="Path"
            variant={"medium"}
            isOpen={isOpen}
            onClose={() => onCancel()}
            actions={[
                <Button key="confirm" variant="primary"
                        isDisabled={Object.getOwnPropertyNames(errors).length > 0}
                        onClick={event => handleSubmit(onSubmit)()}
                >
                    Confirm
                </Button>,
                <Button key="cancel" variant="link"
                        onClick={e => {
                            onCancel();
                        }}>
                    Cancel
                </Button>
            ]}
            onEscapePress={e => onCancel()}>
            <Form autoComplete="off">
                {getTextField('name', 'Path', {
                    length: v => v.length > 0 || 'Path is required',
                    slash: v => v.startsWith("/") || 'Path should start with "/"',
                })}
                <TreeView className='paameters-tree'
                          defaultAllExpanded
                    // hasSelectableNodes
                          data={options}
                          hasGuides={true}
                    // activeItems={activeItem ? [activeItem] : []}
                    // onSelect={onSelect}
                          hasBadges
                />
            </Form>
        </Modal>
    )
}
