import React, {useEffect} from 'react';
import {
    CardBody,
    Bullseye,
    Card,
    CardTitle,
    Text,
    Flex,
    TextContent,
    Form,
    FormGroup,
    TextInput,
    ActionGroup,
    Button,
    InputGroup,
    InputGroupItem,
    Alert,
    FormAlert, Switch
} from '@patternfly/react-core';
import './BasicLoginPage.css'
import EyeIcon from '@patternfly/react-icons/dist/esm/icons/eye-icon';
import EyeSlashIcon from '@patternfly/react-icons/dist/esm/icons/eye-slash-icon';
import {useNavigate} from "react-router-dom";
import {TalismanPlatformVersion} from "../talisman/TalismanUI";
import {AccessApi} from "../talisman/acl/AccessApi";
import {useAppConfigStore} from "../api/ProjectStore";
import {shallow} from "zustand/shallow";

const LICENSE_AGREED = "TALISMAN_LICENSE_AGREED";

export const BasicLoginPage: React.FunctionComponent = () => {

    const [setAuthorized] = useAppConfigStore((s) => [s.setAuthorized], shallow)

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordHidden, setPasswordHidden] = React.useState(true);
    const [showError, setShowError] = React.useState(false);
    const [error, setError] = React.useState('');
    const [licenseChecked, setLicenseChecked] = React.useState<boolean>(false);
    const navigate = useNavigate();

    const [titleSvg, setTitleSvg] = React.useState<string>();

    useEffect(() => {
        const licenseAgreed = localStorage.getItem(LICENSE_AGREED);
        if (licenseAgreed === 'true'){
            setLicenseChecked(true);
        }
        AccessApi.getBrand(config => {
            setTitleSvg(config?.titleSvg)
        })
    }, []);

    function onLoginButtonClick(event: any) {
        event.preventDefault();
        AccessApi.login(username, password, (ok, res) => {
            if (!ok) {
                setError(res?.response?.data);
                setShowError(true);
            } else {
                localStorage.setItem(LICENSE_AGREED, 'true');
                setError('');
                setShowError(false);
                setAuthorized(true);
                navigate('/')
            }
        })
    }

    function getButtonDisabled():boolean {
        return !licenseChecked || (username?.length < 3 || password?.length < 3);
    }

    function getLogo() {
        return (
            <Flex direction={{default: "row"}}
                  justifyContent={{default: 'justifyContentFlexStart'}}
                  alignItems={{default: 'alignItemsCenter'}}
                  style={{padding: '16px', position: 'absolute', right: 0}}
                  gap={{default: 'gapSm'}}
            >
                {TalismanPlatformVersion(titleSvg)}
            </Flex>
        )
    }

    function onKeyDown(event: React.KeyboardEvent<HTMLFormElement>): void {
        event.stopPropagation();
        if (event.key === 'Enter' && licenseChecked) {
            event.preventDefault();
            onLoginButtonClick(event);
        }
    }

    function getLoginForm() {
        return (
            <Form onKeyDown={onKeyDown}>
                <FormGroup fieldId="username" label="Username" isRequired>
                    <TextInput className="text-field"
                               type="text"
                               id="username"
                               name="username"
                               value={username}
                               onChange={(_, value) => setUsername(value)}/>
                </FormGroup>
                <FormGroup fieldId="password" label="Password" isRequired>
                    <InputGroup>
                        <InputGroupItem isFill>
                            <TextInput className="text-field"
                                       type={passwordHidden ? "password" : 'text'}
                                       id="password"
                                       name="password"
                                       value={password}
                                       onChange={(_, value) => setPassword(value)}
                            />
                        </InputGroupItem>
                        <InputGroupItem>
                            <Button
                                variant="control"
                                onClick={() => setPasswordHidden(!passwordHidden)}
                                aria-label={passwordHidden ? 'Show password' : 'Hide password'}
                            >
                                {passwordHidden ? <EyeIcon /> : <EyeSlashIcon />}
                            </Button>
                        </InputGroupItem>
                    </InputGroup>
                </FormGroup>
                <Switch id="license"
                        label={<div>I agree to the <a href="https://docs.talismanplatform.com/subscription/license/">Talisman Platform License</a></div>}
                        isChecked={licenseChecked}
                        onChange={(_, checked) => setLicenseChecked(checked)}
                />
                <ActionGroup>
                    <Button variant="primary"
                            style={{width:'100%'}}
                            isDisabled={getButtonDisabled()}
                            onClick={onLoginButtonClick}
                    >
                        Login
                    </Button>
                </ActionGroup>
                {showError && (
                    <FormAlert>
                        <Alert variant="danger" title={<div>{error?.toString()}</div>} aria-live="polite" isInline />
                    </FormAlert>
                )}
            </Form>
        )
    }


    return (
        <div className='login-page' style={{height: "100%"}}>
            {getLogo()}
            <Bullseye>
                <Card isFlat className="login">
                    <CardTitle>
                        <Flex direction={{default: "row"}} justifyContent={{default:"justifyContentSpaceAround"}}>
                            <TextContent>
                                <Text component="h1">Login </Text>
                            </TextContent>
                        </Flex>
                    </CardTitle>
                    <CardBody>
                        {getLoginForm()}
                    </CardBody>
                </Card>
            </Bullseye>
        </div>
    )
}