import React from 'react';
import {
    Button, Card, CardBody,
    DescriptionList, DescriptionListDescription, DescriptionListGroup, DescriptionListTerm,
    Label, Panel, PanelHeader, PanelMain, PanelMainBody, Popover, Text, TextContent,
} from '@patternfly/react-core';
import '../../../designer/karavan.css';
import BellIcon from "@patternfly/react-icons/dist/esm/icons/bell-icon";
import InfoCircleIcon from "@patternfly/react-icons/dist/esm/icons/info-circle-icon";
import CheckCircleIcon from "@patternfly/react-icons/dist/esm/icons/check-circle-icon";
import ExclamationTriangleIcon from "@patternfly/react-icons/dist/esm/icons/exclamation-triangle-icon";
import ExclamationCircleIcon from "@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon";
import {HealthValue} from "../DashboardModels";

export interface Props {
    labelText: string,
    labelClassName: string,
    labelIcon?: React.ReactNode,
    errors: HealthValue[]
}

export function ErrorPopover(props: Props) {

    const alertIcons = {
        custom: <BellIcon/>,
        info: <InfoCircleIcon/>,
        success: <CheckCircleIcon/>,
        warning: <ExclamationTriangleIcon/>,
        danger: <ExclamationCircleIcon/>
    }

    function getError(key: string, value: string) {
        return (
            <DescriptionListGroup>
                <DescriptionListTerm>{key}</DescriptionListTerm>
                <DescriptionListDescription>{value && value.length > 200 ? `${value.substring(0, 200)}...` : value}</DescriptionListDescription>
            </DescriptionListGroup>
        )
    }

    function getErrorLines(error: HealthValue) {
        const err = JSON.parse(error.value);
        return (
            <Panel variant="bordered">
                <PanelHeader>
                    <TextContent>
                        <Text component={"h3"}>{error.name}</Text>
                    </TextContent>
                </PanelHeader>
                <PanelMain>
                    <PanelMainBody>
                        {Object.keys(err).map(key => getError(key, err[key]))}
                    </PanelMainBody>
                </PanelMain>
            </Panel>
        )
    }

    return (
        <Popover
            aria-label="Alert popover"
            minWidth={"50ch"}
            maxWidth={"80ch"}
            hasAutoWidth
            alertSeverityVariant={"danger"}
            headerContent={"Errors"}
            headerIcon={alertIcons["danger"]}
            headerComponent="h2"
            bodyContent={
                <DescriptionList isHorizontal horizontalTermWidthModifier={{default: '20ch'}}>
                    {props.errors.map(e => getErrorLines(e))}
                </DescriptionList>
            }
            // footerContent={popupFooter}
            appendTo={() => document.body}
        >
            <Label className={props.labelClassName} icon={props.labelIcon} color={'red'}>
                <Button variant={"link"} className="container-button">
                    {props.labelText}
                </Button>
            </Label>
        </Popover>
    )

}