
import React from 'react';
import '../../../designer/karavan.css';
import {shallow} from "zustand/shallow";
import "../dashboard.css"
import {ContainerStatus} from "../../../api/ProjectModels";
import {useMetricStore} from "../DashboardStore";
import {Metric} from "../DashboardModels";
import {merge} from "../DashboardFunc";
import {RoutesTable} from "./RoutesTable";
import {CardMessages} from "../top/CardMessages";
import {CardJVM} from "../top/CardJVM";
import {CardCpu} from "../top/CardCpu";
import {ReadmePanel} from "./ReadmePanel";

export interface Props {
    index: number
    container: ContainerStatus
    isExpanded: boolean
}

export function IntegrationExpand (props: Props) {

    const [metrics] = useMetricStore((s) => [s.metrics], shallow)
    const [history] = useMetricStore((s) => [s.history], shallow)
    const container = props.container;

    const metric: Metric = metrics.filter(m => m.containerName === container.containerName).at(0) || new Metric();

    let failedDiffs: number[] = []

    history.filter(m => container.containerName === undefined || m.containerName === container.containerName).forEach(h => {
        const m = h.metrics;
        const f = m ? m.map(x => x.failedDiff) : [];
        failedDiffs = merge(failedDiffs, f);
    })

    return (
        <div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "6px", alignItems: "start"}}>
                <div style={{flexGrow: "5"}}>
                    <RoutesTable metric={metric}/>
                </div>
                <div style={{flexGrow: "3"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "6px", alignItems: "start"}}>
                        <div style={{flexGrow: "1"}}>
                            <CardCpu containerName={container.containerName} hideLegend={true}/>
                        </div>
                        <div style={{flexGrow: "1"}}>
                            <CardJVM containerName={container.containerName} hideLegend={true} showAxis={true}/>
                        </div>
                        <div style={{flexGrow: "1"}}>
                            <CardMessages containerName={container.containerName} hideLegend={true}/>
                        </div>
                    </div>
                </div>
            </div>
            <ReadmePanel projectId={container.projectId}/>
        </div>
    )

}