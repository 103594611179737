import React from 'react';
import {
    Chart, ChartAxis,
    ChartGroup,
    ChartLine, ChartVoronoiContainer
} from "@patternfly/react-charts";
import {shallow} from "zustand/shallow";
import {useMetricStore} from "../DashboardStore";
import {CustomTheme} from "./CustomTheme";
import {merge} from "../DashboardFunc";

export interface Props {
    containerName?: string
    hideLegend?: boolean
}

export function CardCpu(props: Props) {

    const [history] = useMetricStore((s) => [s.history], shallow)

    let cpuProcessUsage: number[] = [0]

    const cName = props.containerName;
    history.filter(m => cName === undefined || m.containerName === cName).forEach(h => {
        const m = h.metrics;
        const i = m ? m.map(x => x.cpuProcessUsage) : [];
        cpuProcessUsage = merge(cpuProcessUsage, i);
    })

    const lastUsage = (cpuProcessUsage.length > 1 ? cpuProcessUsage.at(cpuProcessUsage.length - 1) : 0) || 0;
    const fixed = lastUsage > 1 ? 1 : (lastUsage > 0.01 ? 2 : 4)
    const legend = props.hideLegend !== true ? [{name: `CPU usage: ${lastUsage.toFixed(fixed)}`}] : undefined;
    const padding = props.hideLegend !== true ? {bottom: 50, left: 0, right: 0, top: 10} : {bottom: 10, left: 45, right: 10, top: 40};
    const dependentAxis = props.hideLegend !== true ?  {axis: {strokeWidth: 0}, tickLabels: {fontSize: 0}, ticks: {strokeWidth: 0}} : {axis: {strokeWidth: 1}, tickLabels: {fontSize: 8}, ticks: {strokeWidth: 1}};

    return (
        <Chart
            ariaDesc="CPU usage"
            ariaTitle="CPU usage"
            legendData={legend}
            legendPosition={"bottom"}
            minDomain={{y: 0}}
            width={250}
            height={150}
            padding={padding}
            containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.y?.toFixed(2)}`}  />}
            theme={CustomTheme()}
            showAxis={false}
        >
            <ChartAxis style={{axis: {strokeWidth: 0}, tickLabels: {fontSize: 0}, ticks: {strokeWidth: 0}}} />
            <ChartAxis dependentAxis style={dependentAxis}/>
            <ChartGroup style={{data: {strokeWidth: ({data}) => 1}}}>
                {cpuProcessUsage?.length > 0 && <ChartLine
                    data={cpuProcessUsage.map((v, i) => {
                        return {name: 'CPU', x: i, y: v}
                    })}
                    style={{
                        data: {
                            strokeWidth: '2'
                        }
                    }}
                />}
            </ChartGroup>
        </Chart>
    )

}