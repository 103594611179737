import React from 'react';
import {
    Flex,
    Popover,
    Badge,
    DescriptionListDescription,
    DescriptionListTerm,
    DescriptionListGroup,
    DescriptionList, Text, TextVariants, TextContent
} from '@patternfly/react-core';
import {KaravanApi} from "../api/KaravanApi";
import './main.css';
import UserIcon from "@patternfly/react-icons/dist/js/icons/user-icon";
import {shallow} from "zustand/shallow";
import {useAccessStore} from "../talisman/acl/AccessStore";

export function UserPopupOidc() {

    const [setShowUserModal, showUserModal] = useAccessStore((s) => [s.setShowUserModal, s.showUserModal], shallow);

    return (
        <Popover
            hasAutoWidth
            aria-label="Current user"
            position={"right-end"}
            hideOnOutsideClick={false}
            isVisible={showUserModal}
            shouldClose={(_event, tip) => setShowUserModal(false)}
            shouldOpen={(_event, tip) => setShowUserModal(true)}
            headerContent={
                <TextContent>
                    <Text component={TextVariants.h3}>Profile</Text>
                </TextContent>
            }
            bodyContent={
                <DescriptionList isHorizontal>
                    <DescriptionListGroup>
                        <DescriptionListTerm>UserName:</DescriptionListTerm>
                        <DescriptionListDescription>{KaravanApi.me?.userName}</DescriptionListDescription>
                    </DescriptionListGroup>
                    <DescriptionListGroup>
                        <DescriptionListTerm>Roles:</DescriptionListTerm>
                        <DescriptionListDescription>
                            <Flex direction={{default: "column"}} gap={{default: "gapXs"}}>
                                {KaravanApi.me?.roles && Array.isArray(KaravanApi.me?.roles)
                                    && KaravanApi.me?.roles.map((role: string) => <Badge key={role} id={role}
                                                                                         isRead>{role}</Badge>)}
                            </Flex>
                        </DescriptionListDescription>
                    </DescriptionListGroup>
                </DescriptionList>
            }
        >
            <div style={{alignSelf: 'center'}}>
                <UserIcon className="avatar"/>
            </div>
        </Popover>
)
}