
import React from 'react';
import '../../../designer/karavan.css';
import {shallow} from "zustand/shallow";
import {Chart, ChartDonut, ChartThemeColor} from "@patternfly/react-charts";
import "../dashboard.css"
import {useHealthStore} from "../DashboardStore";
import chart_color_green_300 from "@patternfly/react-tokens/dist/esm/chart_color_green_300";
import chart_color_red_300 from "@patternfly/react-tokens/dist/esm/chart_color_red_100";

export function CardHealth() {

    const [healths] = useHealthStore((s) => [s.healths], shallow)

    const countTotal = healths.length;
    const countUp = healths.filter(h => h.status==='UP').length ;
    const countDown = countTotal - countUp;

    return (
        <Chart
            ariaDesc="Health"
            ariaTitle="Health"
            width={250}
            height={150}
            showAxis={false}
            padding={{bottom: 50, left: 0, right: 0, top: 0}}
            legendData={[{name: `UP: ${countUp}`}, {name: `DOWN: ${countDown}`}]}
            legendPosition="bottom"
            themeColor={ChartThemeColor.multiOrdered}
            colorScale={[
                chart_color_green_300.var,
                chart_color_red_300.var
            ]}
        >
            <ChartDonut
                title={`${countUp}`}
                subTitle={` from ${countTotal}`}
                constrainToVisibleArea
                data={[{x: 'Running', y: countUp}, {x: '', y: countDown}]}
                name="health"
                labels={({datum}) => `UP ${countUp}`}
            />
        </Chart>
    )

}