import React from "react";

export const TALISMAN_PLATFORM = 'Talisman Platform';

export function TalismanPlatformVersion (titleSvg?: string) {
    const talismanBrand = titleSvg === undefined || titleSvg?.length === 0;
    return (<div style={{
        padding: '6px 16px 1px 0',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'end'
    }}>
        {talismanBrand && TalismanPlatformName(20)}
        {talismanBrand && <div style={{fontSize: '12px'}}>4.7.5</div>}
        {!talismanBrand &&
            <img src={titleSvg} alt='Talisman Platform' style={{width: '200px'}}/>
        }
        {!talismanBrand &&
            <div style={{height: '12px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: 'end', marginTop: '6px'}}>
                <div style={{fontSize: '12px'}}>powered by</div>
                <div style={{fontSize: '12px', fontWeight: 'bold', color: '#ec7a08ff'}}>TALISMAN</div>
                <div style={{fontSize: '12px', fontWeight: 'bold', color: '#0066ccff'}}>PLATFORM</div>
                <div style={{fontSize: '12px'}}>4.7.5</div>
            </div>
        }
    </div>)
}

export const TalismanPlatformName = (height: number) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={(292.797 / 20) * height}
        height={height}
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 292 20"
    >
        <defs>
            <linearGradient
                id="a"
                x1={-32.163}
                x2={259.338}
                y1={277.029}
                y2={-5.028}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0%" stopColor="#F69923"/>
                <stop offset="8.048%" stopColor="#F79A23"/>
                <stop offset="41.874%" stopColor="#E97826"/>
            </linearGradient>
            <circle id="b" cx={128} cy={128} r={128}/>
        </defs>
        <text
            xmlSpace="preserve"
            x={-6.12}
            y={161.954}
            style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                fontStretch: "normal",
                fontSize: "226.667px",
                lineHeight: 1.25,
                fontFamily: "Geologica",
                fontVariantLigatures: "normal",
                fontVariantCaps: "normal",
                fontVariantNumeric: "normal",
                fontVariantEastAsian: "normal",
                fontVariationSettings: "&quot",
                fill: "#ec7a08",
                fillOpacity: 1,
                stroke: "none",
                strokeWidth: 5.3487,
            }}
            transform="scale(.12095)"
        >
            <tspan
                x={-6.12}
                y={161.954}
                style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    fontStretch: "normal",
                    fontSize: "226.667px",
                    fontFamily: "Geologica",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontVariantNumeric: "normal",
                    fontVariantEastAsian: "normal",
                    fontVariationSettings: "&quot",
                }}
            >
                {"TALISMAN"}
            </tspan>
        </text>
        <text
            xmlSpace="preserve"
            x={1097.133}
            y={167.696}
            style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                fontStretch: "normal",
                fontSize: "234.284px",
                lineHeight: 1.25,
                fontFamily: "Geologica",
                fontVariantLigatures: "normal",
                fontVariantCaps: "normal",
                fontVariantNumeric: "normal",
                fontVariantEastAsian: "normal",
                fontVariationSettings: "&quot",
                fill: "#06c",
                fillOpacity: 1,
                stroke: "none",
                strokeWidth: 12.9438,
            }}
            transform="scale(.12537 .11669)"
        >
            <tspan
                x={1097.133}
                y={167.696}
                style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    fontStretch: "normal",
                    fontSize: "234.284px",
                    fontFamily: "Geologica",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontVariantNumeric: "normal",
                    fontVariantEastAsian: "normal",
                    fontVariationSettings: "&quot",
                    strokeWidth: 12.9438,
                }}
            >
                {"PLATFORM"}
            </tspan>
        </text>
    </svg>
)

export const JwtIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={20}
        viewBox="0 0 32 32"
        className=''
    >
        <circle cx={25} cy={20} r={1}/>
        <path
            d="M19.414 30H15v-4.414l5.034-5.034A4.607 4.607 0 0 1 20 20a5 5 0 1 1 4.448 4.966ZM17 28h1.586l5.206-5.206.54.124a3.035 3.035 0 1 0-2.25-2.25l.124.54L17 26.414ZM6 8h2v8H6zM2 8h2v8H2zM18 8h2v6h-2zM14 16h-2a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2Zm-2-2h2v-4h-2ZM2 18h2v8H2zM14 18h2v4h-2zM10 26H8a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2Zm-2-2h2v-4H8ZM2 2h2v4H2zM14 2h2v4h-2zM18 2h2v4h-2zM10 6H8a2 2 0 0 1-2-2V2h2v2h2V2h2v2a2 2 0 0 1-2 2Z"/>
        <path
            d="M0 0h32v32H0z"
            data-name="&lt;Transparent Rectangle&gt;"
            style={{
                fill: "none",
            }}
        />
    </svg>
)
export const ModellerIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 32 32"
    >
        <title>{"data-class"}</title>
        <path
            d="M26 16a3.961 3.961 0 0 0-2.02.566l-2.859-2.859 2.293-2.293a2 2 0 0 0 0-2.828l-6-6a2 2 0 0 0-2.828 0l-6 6a2 2 0 0 0 0 2.828l2.293 2.293-2.859 2.859a4.043 4.043 0 1 0 1.414 1.414l2.859-2.859 2.293 2.293a1.977 1.977 0 0 0 .414.31V22h-3v8h8v-8h-3v-4.277a1.977 1.977 0 0 0 .414-.309l2.293-2.293 2.859 2.859A3.989 3.989 0 1 0 26 16ZM8 20a2 2 0 1 1-2-2 2.002 2.002 0 0 1 2 2Zm10 4v4h-4v-4h4Zm-2-8-6-6 6-6 6 6Zm10 6a2 2 0 1 1 2-2 2.002 2.002 0 0 1-2 2Z"/>
        <path
            d="M0 0h32v32H0z"
            data-name="&lt;Transparent Rectangle&gt;"
            style={{
                fill: "none",
            }}
        />
    </svg>
)