import React, {useState} from 'react';
import {
    Button, capitalize,
    Form,
    FormGroup,
    FormHelperText,
    HelperText,
    HelperTextItem,
    Modal,
    TextArea,
    TextInput
} from "@patternfly/react-core";
import Editor from "@monaco-editor/react";
import './SchemaEditor.css'

interface Props {
    isOpen: boolean
    onConfirm: (name: string, description?: string, example?: string) => void
    onCancel: () => void
}

export function ModalDataType(props: Props) {

    type validate = 'success' | 'warning' | 'error' | 'default';
    const [name, setName] = useState<string>('');
    const [validatedName, setValidatedName] = React.useState<validate>('error');
    const [description, setDescription] = useState<string>();
    const [jsonExample, setJsonExample] = useState<string>();

    const {isOpen, onConfirm, onCancel} = props;
    return (
        <Modal
            className="modal-confirm"
            title="New Data Type"
            variant={"medium"}
            isOpen={isOpen}
            onClose={() => onCancel()}
            actions={[
                <Button key="confirm" variant="primary" isDisabled={validatedName !== 'success'}
                        onClick={event => onConfirm(name, description, jsonExample)}
                >
                    Confirm
                </Button>,
                <Button key="cancel" variant="link"
                        onClick={e => {
                            onCancel();
                        }}>
                    Cancel
                </Button>
            ]}
            onEscapePress={e => onCancel()}>
            <Form autoComplete="off">
                <FormGroup label="Name" fieldId="name" isRequired>
                    <TextInput className="text-field" type="text" id="name"
                               validated={validatedName}
                               value={name}
                               onChange={(e, v) => {
                                   if (v?.length < 1 || v?.includes(' ')) {
                                       setName(v);
                                       setValidatedName('error');
                                   } else {
                                       setValidatedName('success')
                                       setName(capitalize(v));
                                   }
                               }}
                    />
                    {validatedName === 'error' &&
                        <FormHelperText>
                            <HelperText>
                                <HelperTextItem variant={validatedName}>
                                    Enter a valid name (only alpha-numeric characters are allowed - no whitespace)
                                </HelperTextItem>
                            </HelperText>
                        </FormHelperText>
                    }
                </FormGroup>
                <FormGroup label="Description" fieldId="description">
                    <TextArea className="text-field" type="text" id="description"
                               value={description}
                               onChange={(e, v) => setDescription(v)}
                    />
                </FormGroup>
                <FormGroup label="JSON Example" fieldId="jsonExample">
                    <div className='json-example-panel'>
                        <Editor
                            height="200px"
                            defaultLanguage={'json'}
                            theme={'light'}
                            value={jsonExample}
                            className={'code-editor'}
                            options={{lineNumbers: 'off', lineDecorationsWidth: 0}}
                            onChange={(value, ev) => {
                                if (value) {
                                    setJsonExample(value)
                                }
                            }}
                        />
                    </div>
                </FormGroup>
            </Form>
        </Modal>
    )
}
