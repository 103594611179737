import React from 'react';
import {
    Badge,
    Button, Flex, FlexItem,
    Modal,
} from '@patternfly/react-core';
import {shallow} from "zustand/shallow";
import {useDashboardStore} from "../DashboardStore";
import {TopologyPanel} from "./TopologyPanel";
import "./topology.css"
import {useProjectsStore} from "../../../api/ProjectStore";

export function TopologyModal () {

    const [showTopology, setShowTopology, projectId] = useDashboardStore((s) =>
        [s.showTopology,s.setShowTopology, s.projectId], shallow);

    const [projects] = useProjectsStore((state) => [state.projects], shallow)

    const project = projects.filter(p => p.projectId === projectId).at(0);

    return (
        <Modal
            title={project?.name}
            width={"90%"}
            isOpen={showTopology}
            onClose={_ => setShowTopology(false)}
            actions={[
                <Flex key='legend' direction={{default:"row"}} style={{width:"100%"}}>
                    <Badge key='legend1' className="total-color">Total</Badge>
                    <Badge key='legend2' className="failed-color">Failed</Badge>
                    <Badge key='legend3' className="inflight-color">Inflight</Badge>
                    <FlexItem align={{default: "alignRight"}}>
                        <Button key="cancel" variant="secondary" onClick={_ => setShowTopology(false)}>Close</Button>
                    </FlexItem>
                </Flex>
            ]}
            className="topology-modal"
        >
            <TopologyPanel/>
        </Modal>
    )
}