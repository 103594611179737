import {DashboardApi} from "./DashboardApi";
import {useHealthStore, useMetricStore} from "./DashboardStore";

export class DashboardService {

    public static refreshAllHealth() {
        DashboardApi.getHealths(healths => {
            useHealthStore.setState({healths: healths});
        })
    }

    public static refreshAllMetrics() {
        DashboardApi.getMetrics(metrics => {
            useMetricStore.setState({metrics: metrics});
        })
    }

    public static refreshAllMetricsHistory() {
        DashboardApi.getMetricsHistory(history => {
            useMetricStore.setState({history: history});
        })
    }
}