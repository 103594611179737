import React, {useState} from 'react';
import {
    Button, capitalize, Label, Modal, Switch
} from '@patternfly/react-core';
import {Tbody, Td, Tr} from "@patternfly/react-table";
import {AccessUser, TALISMAN_ADMIN, TALISMAN_DEVELOPER} from "./AccessModels";
import {useAccessStore} from "./AccessStore";
import {shallow} from "zustand/shallow";
import {AccessApi} from "./AccessApi";
import {KaravanApi} from "../../api/KaravanApi";
import DeleteIcon from "@patternfly/react-icons/dist/js/icons/times-icon";
import {AccessService} from "./AccessService";

interface Props {
    index: number
    user: AccessUser
}

export function UsersTableRow(props: Props) {

    const [setShowUserModal, setCurrentUser] = useAccessStore((s) => [s.setShowUserModal, s.setCurrentUser], shallow);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [command, setCommand] = useState<'add' | 'remove' | 'delete'>();
    const [role, setRole] = useState<string>();

    const user = props.user;

    function executeAction() {
        if (command === 'delete') {
            AccessApi.deleteUser(user.username, result => {
                AccessService.refreshUsers();
            });
        } else {
            if (command === 'remove') {
                if (role === TALISMAN_ADMIN) {
                    user.admin = false;
                } else if (role === TALISMAN_DEVELOPER) {
                    user.developer = false;
                }
            } else if (command === 'add') {
                if (role === TALISMAN_ADMIN) {
                    user.admin = true;
                } else if (role === TALISMAN_DEVELOPER) {
                    user.developer = true;
                }
            }
            if (KaravanApi.me?.roles.includes(TALISMAN_ADMIN)) {
                AccessApi.putUserAsAdmin(user, result => {});
            } else {
                AccessApi.putUser(user, result => {});
            }
        }
        setShowConfirmation(false);
    }

    function getConfirmationText() {
        return command === 'delete'
            ? <div>
                <Label color='red'>{capitalize('' + command)}</Label>
                {" user "}
                {/*{<Label isCompact color='blue'>{role}</Label>}*/}
                {/*{command === 'removeRole' ? " from user " : " to user "}*/}
                {<Label color='blue'>{user.username}</Label>}
                {" ?"}
            </div>
            : <div>
                <Label color='red'>{capitalize('' + command)}</Label>
                {" role "}
                {<Label color='blue'>{role}</Label>}
                {command === 'remove' ? " from user " : " to user "}
                {<Label color='blue'>{user.username}</Label>}
                {" ?"}
            </div>
    }

    function getConfirmation() {
        return (<Modal
            className="modal-confirm"
            title="Confirmation"
            variant={"small"}
            isOpen={showConfirmation}
            onClose={() => setShowConfirmation(false)}
            actions={[
                <Button key="confirm" variant="danger" onClick={e => executeAction()}>Confirm</Button>,
                <Button key="cancel" variant="link"
                        onClick={e => {
                            setCommand(undefined);
                            setRole(undefined);
                            setShowConfirmation(false);
                        }}>Cancel</Button>
            ]}
            onEscapePress={e => setShowConfirmation(false)}>
            {getConfirmationText()}
        </Modal>)
    }

    const isDisabled = user?.username === 'admin';
    return (
        <Tbody>
            {showConfirmation && getConfirmation()}
            <Tr key={user.username}>
                <Td>
                    <Button variant='link' style={{padding: '6px'}} onClick={() => {
                        setCurrentUser(user)
                        setShowUserModal(true)
                    }}>
                        {user.username}
                    </Button>
                </Td>
                <Td>{user.firstName}</Td>
                <Td>{user.lastName}</Td>
                <Td>{user.email}</Td>
                <Td modifier='fitContent' textCenter>
                    <Switch
                        id={user.username + ":admin"}
                        className='switch-role'
                        isChecked={user.admin}
                        ouiaId={'admin'}
                        isDisabled={isDisabled}
                        onClick={(_) => {
                            if (user.username !== 'admin') {
                                setRole(TALISMAN_ADMIN)
                                setCommand(!user.admin ? 'add' : 'remove')
                                setShowConfirmation(true);
                            }
                        }}
                    />
                </Td>
                <Td modifier='fitContent' textCenter>
                    <Switch
                        id={user.username + ":developer"}
                        className='switch-role'
                        isChecked={user.developer}
                        ouiaId={'developer'}
                        isDisabled={isDisabled}
                        onClick={(_) => {
                            if (user.username !== 'admin') {
                                setRole(TALISMAN_DEVELOPER)
                                setCommand(!user.developer ? 'add' : 'remove')
                                setShowConfirmation(true);
                            }
                        }}
                    />
                </Td>
                <Td isActionCell>
                    <Button className="dev-action-button"
                            isDisabled={isDisabled}
                            variant={"plain"}
                            icon={<DeleteIcon/>}
                            style={{padding: '6px', marginLeft: '16px'}}
                            onClick={() => {
                                setCommand('delete')
                                setShowConfirmation(true);
                            }}/>
                </Td>
            </Tr>
        </Tbody>
    )
}