
import React from 'react';
import {
    Badge,
    Button,
    Label,
} from '@patternfly/react-core';
import '../designer/karavan.css';
import {useLogStore, useProjectsStore} from "../api/ProjectStore";
import {shallow} from "zustand/shallow";
import "./dashboard/dashboard.css"
import {ContainerStatus, Project} from "../api/ProjectModels";
import UpIcon from "@patternfly/react-icons/dist/esm/icons/running-icon";
import DownIcon from "@patternfly/react-icons/dist/esm/icons/error-circle-o-icon";
import { useHealthStore} from "./dashboard/DashboardStore";
import {Health} from "./dashboard/DashboardModels";

export interface Props {
    container: ContainerStatus
}

export function ContainerButton(props: Props) {

    const [projects] = useProjectsStore((state) => [state.projects], shallow)
    const [healths] = useHealthStore((s) => [s.healths], shallow)

    const container = props.container;
    const project = projects.filter(p => p.projectId === container.projectId).at(0) || new Project();
    const health = healths.filter(c => c.projectId === container.projectId).at(0) || new Health();
    const isRunning = container && container.state === 'running';
    const isUp = health.status === 'UP';
    const isContextUp = health.contextStatus === 'UP';
    const isConsumersUp = health.consumersStatus === 'UP';
    const isRoutesUp = health.routesStatus === 'UP';
    const isAllUp = isUp && isContextUp && isConsumersUp && isRoutesUp;

    const colorContainer = isRunning && isAllUp? 'green' : (!isRunning ? 'red' : 'grey');

    const iconContainer = isRunning ? <UpIcon/> : <DownIcon/>;
    const type = (project.lastCommitTimestamp > 0) ? container?.type : undefined;
    return (
        <Label icon={iconContainer} color={colorContainer}>
            <Button variant={"link"}
                    className="container-button"
                    onClick={e => {
                        useLogStore.setState({showLog: true, type: 'container', podName: container.containerName});
                    }}
            >
                {container?.containerName}
            </Button>
            {container.state !== 'running' && <>{`: ${container.state}`}</>}
            {type && <Badge isRead>{type}</Badge>}
        </Label>
    )
}