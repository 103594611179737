import React from 'react';
import {Button, Modal} from "@patternfly/react-core";

interface Props {
    isOpen: boolean
    message: string
    onConfirm: () => void
    onCancel: () => void
}

export function ModalDeleteConfirmation(props: Props) {

    const {isOpen, message, onConfirm, onCancel} = props;
    return (
        <Modal
            className="modal-confirm"
            title="Deletion confirmation"
            variant={"small"}
            isOpen={isOpen}
            onClose={() => onCancel()}
            actions={[
                <Button key="confirm" variant="danger" onClick={event => onConfirm()}>
                    Confirm
                </Button>,
                <Button key="cancel" variant="link"
                        onClick={e => {
                            onCancel();
                        }}>
                    Cancel
                </Button>
            ]}
            onEscapePress={e => onCancel()}>
            <div>{message}</div>
        </Modal>
    )
}
