import React, {useEffect} from 'react';
import './SchemaEditor.css';
import {
    Alert, Button,
    Form, FormAlert, Modal,
} from "@patternfly/react-core";
import {shallow} from "zustand/shallow";
import {codeToOpenApiDocument} from "./OpenApiUtils";
import {SubmitHandler, useForm} from "react-hook-form";
import {useFormUtil} from "../util/useFormUtil";
import {useFileStore} from "../api/ProjectStore";
import {Info} from "@apicurio/data-models/src/io/apicurio/datamodels/models/Info";
import {EventBus} from "../designer/utils/EventBus";

interface Props {
    isOpen: boolean
    onConfirm: (info: Info) => void
    onCancel: () => void
}

export function ModalInformation(props: Props) {

    const [file] = useFileStore((s) => [s.file], shallow);
    const [isReset, setReset] = React.useState(false);
    const formContext = useForm<Info>({mode: "all"});
    const {getTextField, getTextArea} = useFormUtil(formContext);
    const {
        formState: {errors},
        handleSubmit,
        reset,
        trigger
    } = formContext;

    useEffect(() => {
        try {
            const document = codeToOpenApiDocument(file?.code);
            reset(document?.getInfo());
            setReset(true);
        } catch (err: any) {
            EventBus.sendAlert("Error", err?.message, "danger")
        }
    }, [reset]);

    React.useEffect(() => {
        isReset && trigger();
    }, [trigger, isReset]);


    const onSubmit: SubmitHandler<Info> = (data) => {
        onConfirm(data)
    }

    const {isOpen, onConfirm, onCancel} = props;

    return (
        <Modal
            className="modal-confirm"
            title="Information"
            variant={"medium"}
            isOpen={isOpen}
            onClose={() => onCancel()}
            actions={[
                <Button key="confirm" variant="primary"
                        isDisabled={Object.getOwnPropertyNames(errors).length > 0}
                        onClick={event => handleSubmit(onSubmit)()}
                >
                    Confirm
                </Button>,
                <Button key="cancel" variant="link"
                        onClick={e => {
                            onCancel();
                        }}>
                    Cancel
                </Button>
            ]}
            onEscapePress={e => onCancel()}>
                <Form isHorizontal={true} autoComplete="off" noValidate>
                    {getTextField('version', 'Version', {})}
                    {getTextField('title', 'Title', {
                        length: v => v.length > 5 || 'Title should be longer that 5 characters',
                    })}
                    {getTextArea('description', 'Description', {
                        length: v => v.length > 5 || 'Description should be longer that 5 characters',
                    })}
                    {getTextField('license.name', 'License Name', {})}
                    {getTextField('license.url', 'License URL', {})}
                </Form>
            </Modal>
    )
}
