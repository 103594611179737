export class JwtAccessUser {
    username: string = '';
    firstName: string = '';
    lastName: string = ''
    password: string = ''
    password2: string = ''
    email: string = '';
    scope: string = '';
    tokenId: string = '';
    tokenExpire?: number;

    public constructor(init?: Partial<JwtAccessUser>) {
        Object.assign(this, init);
    }
}