import React, {useEffect, useState} from 'react';
import {
    Button,
    PageSection, Text,
    TextContent, TextInput,
    Toolbar,
    ToolbarContent, ToolbarItem,
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {MainToolbar} from "../../designer/MainToolbar";
import {UsersTable} from "./UsersTable";
import {useAccessStore} from "./AccessStore";
import {shallow} from "zustand/shallow";
import {AccessService} from "./AccessService";
import {UserModal} from "./UserModal";
import PlusIcon from "@patternfly/react-icons/dist/esm/icons/plus-icon";
import RefreshIcon from "@patternfly/react-icons/dist/esm/icons/sync-alt-icon";

interface Props {
    dark: boolean,
}

export const AccessPage = (props: Props) => {

    const [showUserModal, setShowUserModal, setFilter, filter, setCurrentUser] =
        useAccessStore((s) => [s.showUserModal, s.setShowUserModal, s.setFilter, s.filter, s.setCurrentUser], shallow);

    useEffect(() => AccessService.refreshUsers(), []);

    function tools() {
        return (<Toolbar id="toolbar-group-types">
            <ToolbarContent>
                <ToolbarItem>
                    <Button icon={<RefreshIcon/>}
                            variant={"link"}
                            onClick={e => AccessService.refreshUsers()}
                    />
                </ToolbarItem>
                <ToolbarItem>
                    <TextInput className="text-field" type="search" id="search" name="search"
                               value={filter}
                               onChange={(_event, value) => setFilter(value)}
                               autoComplete="off"
                               placeholder="Search"/>
                </ToolbarItem>
                <ToolbarItem>
                    <Button className="dev-action-button"
                            icon={<PlusIcon/>}
                            onClick={e => {
                                setCurrentUser(undefined)
                                setShowUserModal(true)
                            }}
                    >Create</Button>
                </ToolbarItem>
            </ToolbarContent>
        </Toolbar>);
    }

    function title() {
        return (<TextContent>
            <Text component="h2">Access Control</Text>
        </TextContent>);
    }

    return (
        <PageSection className="container-page" padding={{default: 'noPadding'}}>
            <PageSection className="tools-section" padding={{default: 'noPadding'}}>
                <MainToolbar title={title()} tools={tools()}/>
            </PageSection>
            {showUserModal &&
                <UserModal
                    afterUpdate={user => {
                        AccessService.refreshUsers();
                    }}
                    afterCreate={user => {
                        AccessService.refreshUsers();
                    }}
                />
            }
            <PageSection isFilled className="scrolled-page">
                <UsersTable/>
            </PageSection>
        </PageSection>
    )
}