import React from 'react';
import {
    Card,
    CardBody,
    TextContent,
    TextVariants,
    Text,
    Flex,
    FlexItem, Divider, HelperTextItem, HelperText
} from '@patternfly/react-core';
import '../../../designer/karavan.css';
import "../dashboard.css"
import {CardIntegrations} from "./CardIntegrations";
import {CardDockerMemory} from "./CardDockerMemory";
import {CardHealth} from "./CardHealth";
import {CardMessages} from "./CardMessages";
import {CardJVM} from "./CardJVM";
import {CardCpu} from "./CardCpu";
import {useAppConfigStore} from "../../../api/ProjectStore";
import {shallow} from "zustand/shallow";

export function CardTop() {

    const [config] = useAppConfigStore((state) => [state.config], shallow)
    const inDocker = config.infrastructure === 'docker';
    return (
        <Card className='inventory-card'>
            <CardBody>
                <Flex direction={{default: "row"}} alignSelf={{default: "alignSelfStretch"}}
                      alignItems={{default: "alignItemsStretch"}}
                      alignContent={{default: "alignContentStretch"}}
                >
                    {inDocker && <FlexItem flex={{default: "flex_1"}}>
                        <TextContent>
                            <Text component={TextVariants.h3}>Memory</Text>
                        </TextContent>
                        <HelperText>
                            <HelperTextItem variant="indeterminate">Docker usage (GB)</HelperTextItem>
                        </HelperText>
                        <CardDockerMemory/>
                    </FlexItem>}
                    {inDocker && <Divider orientation={{default: "vertical"}}/>}
                    <FlexItem flex={{default: "flex_1"}}>
                        <TextContent>
                            <Text component={TextVariants.h3}>Integrations</Text>
                        </TextContent>
                        <HelperText>
                            <HelperTextItem variant="indeterminate">Integration containers</HelperTextItem>
                        </HelperText>
                        <CardIntegrations/>
                    </FlexItem>
                    <Divider orientation={{default: "vertical"}}/>
                    <FlexItem flex={{default: "flex_1"}}>
                        <TextContent>
                            <Text component={TextVariants.h3}>Health</Text>
                        </TextContent>
                        <HelperText>
                            <HelperTextItem variant="indeterminate">Services healthcheck</HelperTextItem>
                        </HelperText>
                        <CardHealth/>
                    </FlexItem>
                    <Divider orientation={{default: "vertical"}}/>
                    <FlexItem flex={{default: "flex_1"}}>
                        <TextContent>
                            <Text component={TextVariants.h3}>CPU</Text>
                        </TextContent>
                        <HelperText>
                            <HelperTextItem variant="indeterminate">CPU usage (%)</HelperTextItem>
                        </HelperText>
                        <CardCpu containerName={undefined}/>
                    </FlexItem>
                    <Divider orientation={{default: "vertical"}}/>
                    <FlexItem flex={{default: "flex_1"}}>
                        <TextContent>
                            <Text component={TextVariants.h3}>Memory</Text>
                        </TextContent>
                        <HelperText>
                            <HelperTextItem variant="indeterminate">JVM memory (MB)</HelperTextItem>
                        </HelperText>
                        <CardJVM containerName={undefined}/>
                    </FlexItem>
                    <Divider orientation={{default: "vertical"}}/>
                    <FlexItem flex={{default: "flex_1"}}>
                        <TextContent>
                            <Text component={TextVariants.h3}>Messages</Text>
                        </TextContent>
                        <HelperText>
                            <HelperTextItem variant="indeterminate">Processing messages</HelperTextItem>
                        </HelperText>
                        <CardMessages containerName={undefined}/>
                    </FlexItem>
                </Flex>
            </CardBody>
        </Card>
    )

}