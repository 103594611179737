import React, {useState} from 'react';
import {
    Alert,
    Badge,
    Button,
    ClipboardCopy,
    ClipboardCopyVariant,
    Form,
    FormGroup,
    Label,
    LabelGroup,
    Modal,
    Switch,
    TextInput
} from '@patternfly/react-core';
import {Table, Tbody, Td, Tr} from "@patternfly/react-table";
import KeyIcon from "@patternfly/react-icons/dist/esm/icons/key-icon";
import {shallow} from "zustand/shallow";
import {JwtAccessUser} from "./JwtModels";
import {useJwtStore} from "./JwtStore";
import {JwtApi} from "./JwtApi";
import {JwtService} from "./JwtService";
import {useProjectsStore, useStatusesStore} from "../../api/ProjectStore";
import "./jwt.css"
import {BUILD_IN_PROJECTS} from "../../api/ProjectModels";

interface Props {
    index: number
    user: JwtAccessUser
}

export function JwtUsersTableRow(props: Props) {

    const [allProjects] = useProjectsStore((s) => [s.projects], shallow);
    const [setOpenModal, setCurrentUser] = useJwtStore((s) => [s.setOpenModal, s.setCurrentUser], shallow);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [token, setToken] = useState<any>();
    const [showKey, setShowKey] = useState<boolean>(false);
    const [operation, setOperation] = useState<'delete' | 'add' | 'allServices' | 'noneServices' | 'revokeToken'>();
    const [entity, setEntity] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [allDeployments, allContainers] = useStatusesStore((state) => [state.deployments, state.containers], shallow)

    const {user, index} = props;
    const scope = user.scope?.split(" ") || [];
    const allServices = scope?.includes('ALL_SERVICES') || false
    const projects = allProjects.filter(p => !BUILD_IN_PROJECTS.includes(p.projectId))
    const containers = allContainers
        ?.filter(g => ['devmode', 'project'].includes(g.type))
        ?.filter(g => g.state === 'running')
        ?.map(g => g.projectId);

    function closeConfirmation() {
        setEntity(undefined);
        setShowConfirmation(false)
    }

    function confirmConfirmation() {
        setLoading(true)
        if (operation === 'revokeToken' && entity) {
            JwtApi.revokeToken(entity, (_, __) => {
                setShowConfirmation(false);
                setLoading(false)
                JwtService.refreshUsers();
            })
        } else {
            if (operation === 'noneServices') {
                user.scope = ''
            } else if (operation === 'delete' && entity) {
                scope?.splice(scope?.findIndex(s => s === entity), 1);
                user.scope = scope?.join(' ')
            } else if (operation === 'allServices') {
                user.scope = 'ALL_SERVICES'
            } else if (operation === 'add' && entity) {
                scope?.push(entity);
                user.scope = scope?.join(' ')
            }
            JwtApi.putUser(user, (_, __) => {
                setShowConfirmation(false);
                setLoading(false)
                JwtService.refreshUsers();
            })
        }
    }

    function getConfirmation() {
        return (<Modal
            className="modal-confirm"
            title="Confirmation"
            variant={"small"}
            isOpen={showConfirmation}
            onClose={closeConfirmation}
            actions={[
                <Button key="confirm" isLoading={loading} variant="danger" onClick={confirmConfirmation}>Confirm</Button>,
                <Button key="cancel" variant="link" onClick={closeConfirmation}>Cancel</Button>
            ]}
            onEscapePress={closeConfirmation}>
            {operation === 'noneServices' &&
                <div>
                    <Label color='red'>Delete</Label> access to all services from user <Label color='blue'>{user.username}</Label>?
                </div>
            }
            {operation === 'delete' &&
                <div>
                    <Label color='red'>Delete</Label> access to <Label color='blue'>{entity}</Label> from user <Label color='blue'>{user.username}</Label>?
                </div>
            }
            {operation === 'allServices' &&
                <div>
                    <Label color='red'>Add</Label> access to all services to user <Label color='blue'>{user.username}</Label>?
                </div>
            }
            {operation === 'add' &&
                <div>
                    <Label color='red'>Add</Label> access to <Label color='blue'>{entity}</Label> to user <Label color='blue'>{user.username}</Label>?
                </div>
            }
            {operation === 'revokeToken' &&
                <div>
                    <Label color='red'>Revoke</Label> token <Label color='blue'>{entity}</Label> ?
                </div>
            }
        </Modal>)
    }

    function closeShowKey() {
        setToken(undefined);
        setShowKey(false);
    }

    function getShowKey() {
        return (
            <Modal
                title='JWT Token'
                className="modal-confirm"
                variant={"medium"}
                isOpen={showKey}
                onClose={closeShowKey}
                actions={[<Button key="cancel" variant="primary" onClick={closeShowKey}>Close</Button>]}
                onEscapePress={closeShowKey}>
                <Form isHorizontal>
                    <Alert variant='info' isInline title='Make sure to copy your access token now. You won’t be able to see it again!'/>
                    <FormGroup label="Token Type" fieldId='type' isRequired>
                        <TextInput type="text" id='type' value={token?.token_type} isDisabled/>
                    </FormGroup>
                    <FormGroup label='Access Token' fieldId='access_token'>
                        <ClipboardCopy id='access_token' hoverTip="Copy" clickTip="Copied" variant={ClipboardCopyVariant.expansion} isReadOnly>
                            {token?.access_token}
                        </ClipboardCopy>
                    </FormGroup>
                    <FormGroup label="Expires in (sec)" fieldId='expires_in' isRequired>
                        <TextInput className="text-field" type="text" id='expires_in' value={token?.expires_in} isDisabled/>
                    </FormGroup>
                </Form>
            </Modal>
        )
    }

    function isServiceRunning(serviceName: string) {
        return containers.includes(serviceName)
    }

    function getAddKeyButton() {
        return (
            <Label color="blue" variant="outline"
                   isOverflowLabel onClick={event => {
                JwtApi.generateToken(user.username, token => {
                    if (token) {
                        setToken(token)
                        setShowKey(true)
                    }
                    JwtService.refreshUsers();
                })
            }}
            >
                Generate Token
            </Label>
        )
    }

    const expireDate = (user.tokenExpire !== null && user.tokenExpire !== undefined) ? new Date(user.tokenExpire * 1000) : undefined;
    const expired = (user.tokenExpire !== null && user.tokenExpire !== undefined) ? (Date.now() > (user.tokenExpire * 1000)) : false;
    return (
        <Tbody isExpanded>
            {showConfirmation && getConfirmation()}
            {showKey && getShowKey()}
            <Tr key={user.username}>
                <Td expand={allServices
                    ? undefined
                    : {
                        rowIndex: index,
                        isExpanded: isExpanded,
                        onToggle: () => setIsExpanded(!isExpanded),
                    }}
                />
                <Td>
                    <Button variant='link' style={{padding: '6px'}} onClick={() => {
                        setCurrentUser(user)
                        setOpenModal(true)
                    }}>
                        {user.username}
                    </Button>
                </Td>
                <Td>{user.firstName}</Td>
                <Td>{user.lastName}</Td>
                <Td>{user.email}</Td>
                <Td textCenter>
                    <LabelGroup numLabels={1} addLabelControl={user.tokenId ? undefined : getAddKeyButton()}>
                        {user.tokenId &&
                            <Label color='blue'
                                   icon={user.tokenId ? <KeyIcon/> : undefined}
                                   onClose={_ => {
                                       setOperation('revokeToken')
                                       setEntity(user.tokenId)
                                       setShowConfirmation(true)
                                   }}>
                                {user.tokenId}
                            </Label>
                        }
                    </LabelGroup>
                </Td>
                <Td>
                    {expireDate &&
                        <Label color={expired ? 'red' : 'green'}>{expireDate ? expireDate.toUTCString() : ''}</Label>}
                </Td>
                <Td textCenter>
                    <Switch
                        id={user.username + ":allServices"}
                        className='switch-role'
                        isChecked={allServices}
                        ouiaId={'allServices'}
                        onClick={(_) => {
                            setOperation(allServices ? 'noneServices' : 'allServices')
                            setShowConfirmation(true);
                        }}
                    />
                </Td>
            </Tr>
            <Tr isExpanded={isExpanded && !allServices} style={{padding: "0"}}>
                <Td colSpan={8} className='services-table'>
                    <Table variant='compact' borders={false}>
                        <Tbody>
                            {projects.map((project, i) => {
                                    const serviceChecked = scope?.includes(project.projectId) || false;
                                    return (<Tr>
                                        <Td width={80}/>
                                        <Td modifier='fitContent' style={{textAlign: 'right'}}>
                                            <Label color={isServiceRunning(project.projectId) ? 'green' : 'grey'}>
                                                {project.name} <Badge isRead>{project.projectId}</Badge>
                                            </Label>
                                        </Td>
                                        <Td modifier='fitContent'>
                                            <Switch
                                                id={user.username + ":" + project.projectId}
                                                className='switch-role'
                                                isChecked={serviceChecked}
                                                isDisabled={user.tokenId?.length > 0}
                                                ouiaId={user.username + ":" + project.projectId}
                                                onClick={(_) => {
                                                    if (!user.tokenId) {
                                                        setOperation(serviceChecked ? 'delete' : 'add')
                                                        setEntity(project.projectId);
                                                        setShowConfirmation(true);
                                                    }
                                                }}
                                            />
                                        </Td>
                                    </Tr>)
                                }
                            )}
                        </Tbody>
                    </Table>
                </Td>
            </Tr>
        </Tbody>
    )
}