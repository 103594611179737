
import React, {useState} from 'react';
import {
    Button,
    Label,
    Tooltip,
    TooltipPosition
} from '@patternfly/react-core';
import '../../../designer/karavan.css';
import {shallow} from "zustand/shallow";
import "../dashboard.css"
import {ContainerStatus} from "../../../api/ProjectModels";
import DownIcon from "@patternfly/react-icons/dist/esm/icons/error-circle-o-icon";
import CheckIcon from "@patternfly/react-icons/dist/esm/icons/check-circle-icon";
import {Tbody, Td, Tr} from "@patternfly/react-table";
import {useDashboardStore, useHealthStore, useMetricStore} from "../DashboardStore";
import {Health, Metric} from "../DashboardModels";
import {getRoutesFromMetric, merge} from "../DashboardFunc";
import TopologyIcon from "@patternfly/react-icons/dist/js/icons/topology-icon";
import {ErrorPopover} from "./ErrorPopover";
import {IntegrationExpand} from "./IntegrationExpand";
import {TopologyModal} from "../topology/TopologyModal";
import {ContainerButton} from "../../ContainerButton";
import {useAppConfigStore} from "../../../api/ProjectStore";

export interface Props {
    index: number
    container: ContainerStatus
}

export function IntegrationRow(props: Props) {

    const [config] = useAppConfigStore((state) => [state.config], shallow)
    const [setShowTopology, setProjectId, setContainerName] = useDashboardStore((s) =>
        [s.setShowTopology, s.setProjectId, s.setContainerName], shallow);
    const [healths] = useHealthStore((s) => [s.healths], shallow)
    const [metrics] = useMetricStore((s) => [s.metrics], shallow)
    const [history] = useMetricStore((s) => [s.history], shallow)
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const form = new Intl.NumberFormat('en-US');
    const inDocker = config.infrastructure === 'docker';

    function getMemoryInfo(memoryInfo?: string): string {
        if (inDocker) {
            const parts = memoryInfo ? memoryInfo.split("/") : []
            return parts.at(0) || '';
        } else {
            return memoryInfo || '';
        }
    }

    function getCpuInfo(metric?: Metric): string {
        if (metric && metric.cpuProcessUsage) {
            const fixed = metric.cpuProcessUsage > 1 ? 1 : (metric.cpuProcessUsage > 0.01 ? 2 : 4)
            return metric && metric.cpuProcessUsage ? metric.cpuProcessUsage.toFixed(fixed) : '';
        }
        return '';
    }

    function getUptime(uptime?: number): string {
        if (uptime) {
            const days = Math.floor(uptime / 86400);
            const hours = Math.floor(((uptime / 86400) % 1) * 24);
            const minutes = Math.floor(((uptime / 3600) % 1) * 60);
            const seconds = Math.round(((uptime / 60) % 1) * 60);
            return (
                (days > 0 ? days + 'd ' : '') +
                (hours > 0 ? hours + 'h ' : '') +
                (minutes > 0 ? minutes + 'm ' : '') +
                (seconds > 0 ? seconds + 's' : '')
            );
        } else {
            return '';
        }
    }

    function getJvmMemory(metric?: Metric): string {
        const result: string[] = []
        if (metric) {
            const used= metric?.usedHeap + metric?.usedNonHeap;
            result.push(used ? `${Math.floor(used / 1024 / 1024)} MB` : "")
        }
        return result.join();
    }

    function getLabel(text: string, className: string, icon: React.ReactNode,
                      tooltip?: string,
                      color?: 'blue' | 'cyan' | 'green' | 'orange' | 'purple' | 'red' | 'grey' | 'gold') {
        return (tooltip
                ? <Tooltip content={tooltip} position={TooltipPosition.top}>
                    <Label className={className} icon={icon} color={color}>{text}</Label>
                </Tooltip>
                : <Label className={className} icon={icon} color={color}>{text}</Label>
        )
    }


    const container = props.container;
    const health = healths.filter(c => c.projectId === container.projectId).at(0) || new Health();
    const isRunning = container && container.state === 'running';
    const isUp = health.status === 'UP';
    const isContextUp = health.contextStatus === 'UP';
    const isConsumersUp = health.consumersStatus === 'UP';
    const isRoutesUp = health.routesStatus === 'UP';

    const colorHealth = isUp ? 'green' : 'red';
    const colorContext = isContextUp ? 'green' : 'red';

    const iconCamel = isContextUp && isConsumersUp && isRoutesUp ? <CheckIcon/> : <DownIcon/>;

    const metric: Metric = metrics.filter(m => m.containerName === container.containerName).at(0) || new Metric();
    const routes = getRoutesFromMetric(metric);

    let failedDiffs: number[] = []

    history.filter(m => container.containerName === undefined || m.containerName === container.containerName).forEach(h => {
        const m = h.metrics;
        const f = m ? m.map(x => x.failedDiff) : [];
        failedDiffs = merge(failedDiffs, f);
    })
    const lastFailed = (failedDiffs.length > 1 ? failedDiffs.at(failedDiffs.length - 1) : 0) || 0;

    return (
        <Tbody>
            <Tr key={container.containerName}>
                <Td className="toggle-button" expand={
                    container.containerName && routes && routes.length > 0
                        ? {
                            rowIndex: props.index,
                            isExpanded: isExpanded,
                            onToggle: () => setIsExpanded(!isExpanded),
                            expandId: 'composable-expandable-example'
                        }
                        : undefined}
                    modifier={"fitContent"}>
                </Td>
                <Td>
                    <ContainerButton container={container}/>
                </Td>
                <Td style={{textAlign: "center"}}>
                    {isRunning && container?.memoryInfo && <span>{container?.cpuInfo}</span>}
                </Td>
                <Td style={{textAlign: "center"}}>
                    {isRunning && container?.memoryInfo && <span>{getMemoryInfo(container?.memoryInfo)}</span>}
                </Td>
                <Td style={{textAlign: "center"}}>
                    {isRunning && <span style={{whiteSpace:"nowrap"}}>{getUptime(metric.uptime)}</span>}
                </Td>
                <Td style={{textAlign: "center"}}>
                    {isRunning && container?.cpuInfo && <span>{getCpuInfo(metric)}</span>}
                </Td>
                <Td style={{textAlign: "center"}}>
                    {isRunning && metric.usedHeap !== 0 &&  <span style={{whiteSpace:"nowrap"}}>{getJvmMemory(metric)}</span>}
                </Td>
                <Td style={{textAlign: "center"}}>
                    {health.updateDateTime > 0 && (
                        isRunning
                        ? getLabel(health.contextVersion, '', iconCamel, undefined, colorContext)
                        : <ErrorPopover labelText={health.status} labelClassName='clabel' labelIcon={undefined} errors={health.errors}/>
                    )}
                </Td>
                <Td style={{textAlign: "right"}}>
                    {isRunning && <span style={{whiteSpace:"nowrap"}}>{form.format(metric.total)}</span>}
                </Td>
                <Td style={{textAlign: "right"}}>
                    {isRunning && <span style={{whiteSpace:"nowrap"}}>{form.format(metric.succeeded)}</span>}
                </Td>
                <Td style={{textAlign: "right"}}>
                    {isRunning && <span style={{whiteSpace:"nowrap"}}>{form.format(metric.failed)}</span>}
                </Td>
                <Td style={{textAlign: "right"}}>
                    {isRunning && lastFailed > 0 && <Label className={'elabel'} color={'red'}>{form.format(lastFailed)}</Label>}
                    {isRunning && lastFailed === 0 && <span style={{whiteSpace:"nowrap"}}>{lastFailed}</span>}
                </Td>
                <Td style={{textAlign: "right"}}>
                    {isRunning && metric.inflight === 0 && <span style={{whiteSpace:"nowrap"}}>{metric.inflight}</span>}
                    {isRunning && metric.inflight > 0 && <Label className={'elabel'} color={'blue'}>{form.format(metric.inflight)}</Label>}
                </Td>
                <Td style={{textAlign: "right"}}>
                    <Button variant={"link"} icon={<TopologyIcon/>} onClick={_ => {
                        setProjectId(container.projectId);
                        setContainerName(container.containerName);
                        setShowTopology(true);
                    }}/>
                </Td>
            </Tr>
            <Tr isExpanded={isExpanded && routes && routes.length > 0} style={{padding: "0"}}>
                <Td colSpan={14} modifier={"fitContent"}>
                    <IntegrationExpand index={props.index} container={props.container} isExpanded={isExpanded}/>
                </Td>
            </Tr>
            <TopologyModal/>
        </Tbody>
    )

}