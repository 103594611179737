import React, {useState} from 'react';
import {
    Bullseye,
    EmptyState, EmptyStateIcon, EmptyStateVariant,
    Spinner,
    EmptyStateHeader
} from '@patternfly/react-core';
import './AccessPage.css';
import {
	TableVariant,
	Tbody,
	Td,
	Th,
	Thead,
	Tr
} from '@patternfly/react-table';
import {
	Table
} from '@patternfly/react-table/deprecated';
import SearchIcon from "@patternfly/react-icons/dist/esm/icons/search-icon";
import {shallow} from "zustand/shallow";
import {UsersTableRow} from "./UsersTableRow";
import {useAccessStore} from "./AccessStore";
import {AccessUser} from "./AccessModels";

export function UsersTable () {

    const [users, filter] = useAccessStore((s) => [s.users, s.filter], shallow);
    const [loading] = useState<boolean>(true);

    function getEmptyState() {
        return (
            <Tbody>
                <Tr>
                    <Td colSpan={8}>
                        <Bullseye>
                            {loading && <Spinner className="progress-stepper" diameter="80px" aria-label="Loading..."/>}
                            {!loading &&
                                <EmptyState variant={EmptyStateVariant.sm}>
                                    <EmptyStateHeader titleText="No results found" icon={<EmptyStateIcon icon={SearchIcon}/>} headingLevel="h2" />
                                </EmptyState>
                            }
                        </Bullseye>
                    </Td>
                </Tr>
            </Tbody>
        )
    }

    const conts = users.filter(user =>
        user.username?.toLowerCase().includes(filter)
        || user.firstName.toLowerCase().includes(filter)
        || user.lastName.toLowerCase().includes(filter)
    );
    return (
        <Table aria-label="Projects" variant={TableVariant.compact}>
            <Thead>
                <Tr>
                    <Th key='username'>Username</Th>
                    <Th key='firstName'>First Name</Th>
                    <Th key='lastName'>Last Name</Th>
                    <Th key='email'>Email</Th>
                    <Th modifier='fitContent' key='admin'>Administrator</Th>
                    <Th modifier='fitContent' key='developer'>Developer</Th>
                    <Th key='action'></Th>
                </Tr>
            </Thead>
            {conts?.map((user: AccessUser, index: number) => (
                <UsersTableRow key={user.username} index={index} user={user}/>
            ))}
            {conts?.length === 0 && getEmptyState()}
        </Table>
    )

}